export default [
  {
    path: '/coach/details/:id',
    name: 'coachDetails',
    component: () => import('@/views/coach/details/index'),
    meta: {
      title: '教练详情',
    },
  },
  {
    path: '/coach-collect',
    name: 'coachCollect',
    component: () => import('../views/coach/coach-collect'),
    meta: {
      title: '收藏教练',
    },
  },
  {
    path: '/coach/intro',
    name: 'coachIntro',
    component: () => import('../views/coach/coach-intro'),
    meta: {
      title: '入驻教练',
      requiresAuth: true,
    },
  },
]
