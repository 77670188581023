export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user-info/user'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/personal-info',
    name: 'personalInfo',
    component: () => import('../views/user-info/personal-info'),
    meta: {
      title: '个人资料',
      requiresAuth: true,
    },
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/user-info/message'),
    meta: {
      title: '系统消息',
      requiresAuth: true,
    },
  },
]
