import axios from 'axios'
import { Toast } from 'vant'
import { toLogin } from '@/common'
import { localProxyStorage } from '@/utils/storage'
import { apiURL } from '@/config'
import { clearStorage } from '@/utils'
import router from '@/router'

const contentType = {
  form: 'application/x-www-form-urlencoded',
  json: 'application/json',
  file: 'multipart/form-data',
}

const http = axios.create({
  baseURL: process.env.VUE_APP_RUN_ENV !== 'development' ? apiURL : '',
  timeout: 12000,
  headers: {
    'Content-Type': contentType.json,
    appName: 'APP',
    channelName: 'H5',
  },
})

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    config.url = '/proxy' + config.url

    if (localProxyStorage?.user?.authToken) {
      config.headers['auth-token'] = localProxyStorage.user.authToken
    }
    config.headers['Content-Type'] = contentType[config.contentType || 'json']
    // config.headers["x-version"] = "25";
    // config.headers["x-first-ip"] = "10.0.0.25";
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    const { status, data } = response
    if (status === 200) {
      if (data.code === 0) {
        return data
      } else if (data.code === 100003 || data.code === 100004) {
        // 登录过期 or 账号异地登录
        clearStorage()
        Toast(data.msg)
        toLogin()
        return Promise.reject(data)
      } else if (data.code === 113013) {
        // 教练不存在
        Toast({
          message: '您还不是教练身份，快去申请入驻吧 😃',
          duration: 3000,
        })
        router.push({
          name: 'coachApplyForm',
        })
        return Promise.reject(data)
      } else if (data.code === 113024) {
        // 教练账号被禁用，跳转到禁用页面提示
        router.push({
          name: 'myWorktableAccountDisabled',
        })
        return Promise.reject(data)
      } else if (data.code === 110009) {
        // 微信授权登录问题，返回data回去即可
        return data
      } else {
        Toast(data.msg || '接口异常')
        return Promise.reject(data)
      }
    } else {
      console.log('网络请求异常：', status)
    }

    return response
  },
  function (error) {
    // 对响应错误做点什么
    Toast('服务器异常，请稍后再试')
    return Promise.reject(error)
  },
)

export default http
