export default [
  // 教练招募活动第一期 Start
  {
    path: '/act/a20230321jlzm',
    name: 'a20230321jlzm',
    component: () => import('../views/act/a20230321jlzm/index'),
    meta: {
      title: '明星教练计划，全网优质教练招募',
      keepAlive: true,
    },
  },
  {
    path: '/act/a20230321jlzm/enter-for',
    name: 'a20230321jlzmenterfor',
    component: () => import('../views/act/a20230321jlzm/enter-for'),
    meta: {
      title: '活动报名信息填写',
    },
  },
  {
    path: '/act/a20230321jlzm/collect-gift-bag',
    name: 'a20230321CollectGiftBag',
    component: () => import('../views/act/a20230321jlzm/collect-gift-bag'),
    meta: {
      title: '领取礼包',
    },
  },
  {
    path: '/act/a20230321jlzm/oauth',
    name: 'a20230321Oauth',
    component: () => import('../views/act/a20230321jlzm/oauth'),
  },
  // 教练招募活动第一期 End

  // 教练招募活动第二期 Start
  {
    path: '/act/a20230510jlzm',
    name: 'a20230510jlzm',
    component: () => import('../views/act/a20230510jlzm/index'),
    meta: {
      title: '明星教练计划，全网优质教练招募',
      keepAlive: true,
    },
  },
  {
    path: '/act/a20230510jlzm/enter-for',
    name: 'a20230510jlzmenterfor',
    component: () => import('../views/act/a20230510jlzm/enter-for'),
    meta: {
      title: '活动报名信息填写',
    },
  },
  {
    path: '/act/a20230510jlzm/collect-gift-bag',
    name: 'a20230510CollectGiftBag',
    component: () => import('../views/act/a20230510jlzm/collect-gift-bag'),
    meta: {
      title: '领取礼包',
    },
  },
  {
    path: '/act/a20230510jlzm/oauth',
    name: 'a20230510Oauth',
    component: () => import('../views/act/a20230510jlzm/oauth'),
  },
  // 教练招募活动第二期 End
]
