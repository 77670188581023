export default [
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/category/index'),
    meta: {
      title: '找教练',
      keepAlive: true,
    },
  },
]
