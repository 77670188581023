import { iosRefresh } from '@/common'

export default [
  {
    path: '/news-list',
    name: 'newsList',
    component: () => import('../views/news/news-list'),
    meta: {
      title: '资讯',
      keepAlive: true,
    },
  },
  {
    path: '/news-details/:articleId',
    name: 'newsDetails',
    beforeEnter: iosRefresh,
    component: () => import('../views/news/news-details'),
    meta: {
      title: '资讯详情',
    },
  },
]
