import { iosRefresh } from '@/common'

export default [
  {
    path: '/train-list',
    name: 'trainList',
    component: () => import('../views/train/train-list'),
    meta: {
      title: '培训信息',
      keepAlive: true,
    },
  },
  {
    path: '/train-details/:trainId',
    name: 'trainDetails',
    beforeEnter: iosRefresh,
    component: () => import('../views/train/train-details'),
    meta: {
      title: '培训详情',
      keepAlive: true,
    },
  },
]
