<template>
  <van-action-sheet
    v-model:show="actionSheetShow"
    teleport="body"
    lock-scroll
    v-bind="attrs"
    class="i-action-sheet"
    @close="onClose"
  >
    <div class="i-action-sheet-content">
      <slot />
    </div>
    <div v-if="showConfirmButton || showCancelButton" class="action-sheet-footer">
      <button v-if="showConfirmButton" class="btn cancel" @click="handleCancel">
        {{ cancelText }}
      </button>
      <button v-if="showCancelButton" class="btn confirm" v-preventReClick @click="emit('confirm')">
        {{ confirmText }}
      </button>
    </div>
  </van-action-sheet>
</template>

<script>
  export default { name: 'IjlActionSheet' }
</script>

<script setup>
  import { ref, useAttrs, watch } from 'vue'

  const attrs = useAttrs()
  const emit = defineEmits(['confirm', 'cancel', 'close', 'update:modelValue'])

  const actionSheetShow = ref(false)

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    confirmText: {
      type: String,
      default: '确认',
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  })

  watch(
    () => props.modelValue,
    (newVal) => (actionSheetShow.value = newVal),
    { immediate: true },
  )

  const handleCancel = () => {
    emit('update:modelValue', false)
    emit('cancel')
  }

  const onClose = () => {
    emit('update:modelValue', false)
    emit('close')
  }
</script>

<style lang="scss">
  .i-action-sheet {
    width: initial;
    //min-height: 80%;
    left: var(--window-left);
    right: var(--window-right);

    .van-action-sheet__header {
      font-size: 0.16rem;
      color: #616568;
      line-height: 0.48rem;
    }

    .i-action-sheet-content {
      position: relative;
      width: 100%;
      padding-bottom: 0.65rem;
      // 不能去除该属性
      min-height: calc(100vh - 1.08rem);
    }

    .action-sheet-footer {
      width: 100%;
      min-height: 0.6rem;
      display: flex;
      background: #fff;
      gap: 0.08rem;
      position: absolute;
      bottom: 0;
      padding: 0.1rem 0.15rem;
      padding-bottom: calc(
        var(--wx-safe-area-inset-bottom) + 0.01rem + constant(safe-area-inset-bottom)
      );
      padding-bottom: calc(
        var(--wx-safe-area-inset-bottom) + 0.01rem + env(safe-area-inset-bottom)
      );
    }

    .confirm {
      background: #ff9b26;
      color: #fff;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .cancel {
      border: 1px solid #dddddd;
      color: #616568;
    }

    .btn {
      font-size: 0.16rem;
      flex: 1;
      height: 0.4rem;
      border-radius: 0.23rem;
    }
  }
</style>
