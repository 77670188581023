// 高德获取位置
import axios from 'axios'
import { districtsURL } from '@/config'

// 高德ip定位接口
export const reqIpLocation = () => {
  return axios.get('https://restapi.amap.com/v3/ip?key=80912f26af58c13f0cfd7d4ff10dfb1e')
}

// 获取全国地区城市数据
export const reqAreaOptions = () => {
  return axios.get(districtsURL)
}
