export default [
  {
    path: '/student/attent-class',
    name: 'studentAttentClass',
    component: () => import('../views/student-module/student-class/attent-class'),
    meta: {
      title: '我要上课',
      keepAlive: true,
    },
  },
  {
    path: '/student/class-details',
    name: 'studentClassDetails',
    component: () => import('../views/student-module/student-class/class-details'),
    meta: {
      title: '上课详情',
    },
  },
  {
    path: '/student/history-order',
    name: 'studentHistoryOrder',
    component: () => import('../views/student-module/student-class/history-order'),
    meta: {
      title: '历史订单',
    },
  },
  {
    path: '/student/order',
    name: 'studentOrder',
    component: () => import('../views/student-module/order/order-list'),
    meta: {
      title: '爱教练',
      keepAlive: true,
    },
  },
  {
    path: '/student/order/details',
    name: 'studentOrderDetails',
    component: () => import('../views/student-module/order/order-details'),
    meta: {
      title: '爱教练',
    },
  },
  {
    path: '/student/order/refund-details',
    name: 'studentOrderRefundDetails',
    component: () => import('../views/student-module/order/refund-order-details'),
    meta: {
      title: '爱教练',
    },
  },
  {
    path: '/student/order/apply-refund',
    name: 'studentOrderApplyRefund',
    component: () => import('../views/student-module/order/apply-refund'),
    meta: {
      title: '爱教练',
    },
  },
]
