import InviterTop from './inviter-top'
import Dialog from './dialog'
import ActionSheet from './action-sheet'
import Image from './image'
import GoodsAction from './goods-action/goods-action'
import GoodsActionIcon from './goods-action/goods-action-icon'
import GoodsActionButton from './goods-action/goods-action-button'
import Skeleton from './skeleton/skeleton'
import SkeletonImage from './skeleton/skeleton-image'
import SkeletonRow from './skeleton/skeleton-row'

// 常用的才放进来
const components = [
  InviterTop,
  Dialog,
  ActionSheet,
  Image,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Skeleton,
  SkeletonImage,
  SkeletonRow,
]

const install = (app) => {
  components.forEach((component) => {
    app.component(component.name, component) // 每个组件必需提供 name 属性
  })
}

export default {
  install,
}
