import { iosRefresh } from '@/common'

export default [
  {
    path: '/shop/details/:id',
    name: 'shopDetails',
    beforeEnter: iosRefresh,
    component: () => import('../views/shop/shop-details'),
    meta: {
      title: '场馆详情',
      // keepAlive: true,
    },
  },
]
