/*
 * v-error-img
 * 图片404使用的默认占位图
 * */
export const errorImg = {
  mounted(el) {
    el.addEventListener('error', function () {
      el.src = require('../assets/images/default-img.png')
    })
  },
}

/*
 * v-preventReClick
 * 按钮防重复点击, 配合 loading 使用
 * */
export const preventReClick = {
  mounted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  },
}

/*
 * v-default-avatar
 * 头像图片加载为404，展示默认头像
 * */
export const defaultAvatar = {
  mounted(el) {
    el.addEventListener('error', function () {
      el.src = require('../assets/images/default-avatar.png')
    })
  },
}
