import { ref, nextTick } from 'vue'

const usePullToRefresh = (config, pullDownRefresh) => {
  let refreshContainerElem, refreshControllerElem, refreshInnerElemStyle

  const PULLING = 'pulling' // 下拉中
  const REACHED = 'reached' // 下拉刷新距离达到
  const ABORTING = 'aborting' //下拉刷新距离未达到
  const REFRESHING = 'refreshing' // 刷新
  const RESTORING = 'restoring' // 恢复初始;

  const options = {
    support: true,
    color: config.color || '#2BD009',
    style: config.style || 'circle',
    height: config.height || 70,
    range: config.range || 150,
    offset: config.offset || 0,
  }

  const touchId = ref(null)
  const startY = ref(0)
  const state = ref(null)

  const processDeltaY = (evt, identifier, startY) => {
    const touch = Array.prototype.slice
      .call(evt.changedTouches)
      .filter((touch) => touch.identifier === identifier)[0]
    if (!touch) {
      return false
    }
    evt.deltaY = touch.pageY - startY
    return true
  }

  const toggleClass = (type) => {
    if (!state.value) {
      return
    }

    const elem = refreshContainerElem
    if (elem) {
      elem.classList[type]('page-refresh--' + state.value)
    }
  }

  const addClass = () => {
    toggleClass('add')
  }

  const removeClass = () => {
    toggleClass('remove')
  }

  const pulling = (deltaY) => {
    const elem = refreshControllerElem

    if (!elem) {
      return
    }
    const style = elem.style

    let rotate = deltaY / options.range

    if (rotate > 1) {
      rotate = 1
    } else {
      rotate = rotate * rotate * rotate
    }

    const y = Math.round(deltaY / (options.range / options.height))

    style.webkitTransform = y ? 'translate3d(-50%, ' + y + 'px, 0)' : 0
    style.clip = 'rect(' + (45 - y) + 'px,45px,45px,-5px)'

    refreshInnerElemStyle.webkitTransform = 'rotate(' + 360 * rotate + 'deg)'
  }

  // 刷新
  const refreshing = () => {
    const elem = refreshControllerElem
    if (!elem) {
      return
    }

    const style = elem.style
    style.webkitTransition = '-webkit-transform 0.2s'
    style.webkitTransform = 'translate3d(-50%, ' + options.height + 'px, 0)'

    pullDownRefresh && pullDownRefresh({ stopPullDownRefresh })
  }

  // 恢复初始状态
  const restoring = (callback) => {
    const elem = refreshControllerElem
    if (!elem) {
      return
    }

    const style = elem.style

    // 执行第一段动画
    style.webkitTransition = '-webkit-transform 0.3s'
    style.webkitTransform += ' scale(0.01)'

    // 第一段动画结束后处理工作
    const restoreTransitionEnd = function () {
      timeout && clearTimeout(timeout)
      elem.removeEventListener('webkitTransitionEnd', restoreTransitionEnd)
      style.webkitTransition = ''
      style.webkitTransform = 'translate3d(-50%, 0, 0)'
      callback()
    }
    elem.addEventListener('webkitTransitionEnd', restoreTransitionEnd)
    const timeout = setTimeout(restoreTransitionEnd, 350) // 部分手机，部分情况webkitTransitionEnd不触发
  }

  // 中断处理
  const aborting = (callback) => {
    const elem = refreshControllerElem
    if (!elem) {
      return
    }

    const style = elem.style

    if (style.webkitTransform) {
      style.webkitTransition = '-webkit-transform 0.3s'
      style.webkitTransform = 'translate3d(-50%, 0, 0)'
      const abortTransitionEnd = function () {
        timeout && clearTimeout(timeout)
        elem.removeEventListener('webkitTransitionEnd', abortTransitionEnd)
        style.webkitTransition = ''
        callback()
      }
      elem.addEventListener('webkitTransitionEnd', abortTransitionEnd)
      const timeout = setTimeout(abortTransitionEnd, 350) // 部分手机，部分情况webkitTransitionEnd不触发
    } else {
      callback()
    }
  }

  // 停止加载
  const stopPullDownRefresh = () => {
    if (state.value === REFRESHING) {
      removeClass()
      state.value = RESTORING
      addClass()

      restoring(() => {
        removeClass()
        state.value = null
      })
    }
  }

  const touchstart = (event) => {
    const touch = event.changedTouches[0]
    touchId.value = touch.identifier
    startY.value = touch.pageY
  }

  const touchmove = (event) => {
    // 如果当前页面滚动条距离不等于0, 则不触发下拉刷新
    if ((document.documentElement.scrollTop || document.body.scrollTop) !== 0) {
      touchId.value = null
      return
    }

    if (!processDeltaY(event, touchId.value, startY.value)) {
      return
    }

    let { deltaY } = event

    if (deltaY < 0) {
      deltaY = 0
    }

    event.preventDefault()

    const reachedState = deltaY >= options.range && state.value !== REACHED
    const pullingState = deltaY < options.range && state.value !== PULLING

    if (reachedState || pullingState) {
      removeClass()
      state.value = state.value === REACHED ? PULLING : REACHED
      addClass()
    }

    pulling(deltaY)
  }

  const touchend = (event) => {
    if (!processDeltaY(event, touchId.value, startY.value)) {
      return
    }
    if (state.value === null) {
      return
    }

    // 滑动结束, 下拉距离未达到目前
    if (state.value === PULLING) {
      removeClass()
      state.value = ABORTING
      addClass()
      aborting(() => {
        removeClass()
        state.value = null
      })
    }

    // 如果滑动结束, 下拉刷新距离达到目标
    if (state.value === REACHED) {
      removeClass()
      state.value = REFRESHING
      addClass()
      refreshing()
    }
  }

  const touchcancel = () => {}

  const init = () => {
    refreshContainerElem = document.querySelector('ijiaolian-page-refresh')
    if (!refreshContainerElem) return
    refreshControllerElem = document.querySelector('.page-refresh')
    refreshInnerElemStyle = document.querySelector('.page-refresh-inner').style
  }

  nextTick(() => {
    init()
  })

  return {
    touchstart,
    touchmove,
    touchend,
    touchcancel,
  }
}

export default usePullToRefresh
