<template>
  <div class="inviter" v-if="isShow">
    <div class="inviter-avatar">
      <van-image class="image" fit="cover" :src="getOssURL(userInfo.headImg)" />
    </div>
    <div class="inviter-info">
      <p class="inviter-name omit">{{ userInfo.realName || userInfo.userName }}</p>
      <p class="inviter-desc">向你分享了「爱教练」</p>
    </div>
    <button class="more-btn" @click="toHome">查看更多</button>
  </div>
</template>

<script>
  export default { name: 'InviterTop' }
</script>

<script setup>
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getShareUserInfo } from '@/api/user-server'
  import { getOssURL } from '@/common'
  import { sessionProxyStorage } from '@/utils/storage'

  const route = useRoute()
  const router = useRouter()

  let shareId = null

  if (route.query.shareId) {
    shareId = route.query.shareId?.replace(/ /g, '+')
  } else {
    shareId = sessionProxyStorage.shareId?.replace(/ /g, '+')
  }

  const userInfo = ref(null)
  const isShow = ref(false)

  const initialization = () => {
    if (sessionProxyStorage.inviter) {
      userInfo.value = sessionProxyStorage.inviter
      isShow.value = true
    } else {
      if (shareId) {
        let params = {
          ijlShare: {
            shareId,
          },
        }
        getShareUserInfo(params).then((res) => {
          userInfo.value = res.data
          sessionProxyStorage.inviter = res.data
          isShow.value = true
        })
      }
    }
  }
  const toHome = () => {
    isShow.value = false
    router.push({ name: 'home' })
  }
  initialization()
</script>

<style lang="scss" scoped>
  .inviter {
    width: 100%;
    height: 0.58rem;
    display: flex;
    align-items: center;
    padding: 0.09rem 0.1rem;
    background: url('../../assets/images/inviter-top-bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .inviter-avatar {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .inviter-info {
    margin-left: 0.08rem;

    .inviter-name {
      width: 2.3rem;
      font-size: 0.15rem;
      font-weight: 600;
      color: #170606;
    }

    .inviter-desc {
      font-size: 0.12rem;
      color: #453838;
    }
  }

  .more-btn {
    width: 0.8rem;
    border-radius: 0.19rem;
    background: #ff8c00;
    height: 0.32rem;
    font-size: 0.14rem;
    color: #fff;
  }

  .image {
    width: 100%;
    height: 100%;
  }
</style>
