import { checkUserIsRegisterCoach, getIsSubmitCoachForm } from '@/api/coach-server'
import { sessionProxyStorage } from '@/utils/storage'
import { isLogin, toLogin } from '@/common'
import { Toast } from 'vant'

// 验证用户是否注册为教练身份，且账户是否被禁用
const verifyIdentity = (to, form, next) => {
  if (!isLogin()) {
    toLogin()
    next(false)
    return
  }

  if (sessionProxyStorage.identity && sessionProxyStorage.identity === 'coach') {
    next()
  } else {
    checkUserIsRegisterCoach().then((res) => {
      const { data } = res
      if (data) {
        sessionProxyStorage.identity = 'coach'
        next()
      } else {
        // 入驻教练申请为待审核状态 不做Toast提示
        getIsSubmitCoachForm().then((res) => {
          const { data } = res
          if (!data) {
            let toast = Toast({
              message: '您还不是教练身份，快去申请入驻吧 😃 3s',
              duration: 3000,
            })

            let second = 3
            let timer = setInterval(() => {
              second--
              if (second) {
                toast.message = `您还不是教练身份，快去申请入驻吧 😃 ${second}s`
              } else {
                clearInterval(timer)
                timer = null
                Toast.clear()
                next({
                  name: 'myWorktableToWeapp',
                })
              }
            }, 1000)
          } else {
            next({
              name: 'myWorktableToWeapp',
            })
          }
        })
      }
    })
  }
}

export default [
  {
    path: '/my-worktable/coach',
    name: 'myWorktableCoach',
    component: () => import('../views/coach-worktable/home'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '教练工作台',
    },
  },
  {
    path: '/my-worktable/to-weapp',
    name: 'myWorktableToWeapp',
    component: () => import('../views/coach-worktable/to-weapp.vue'),
    meta: {
      title: '打开小程序',
    },
  },
  {
    path: '/my-worktable/coach/recruit-posts',
    name: 'coachWorktableRecruitPosts',
    component: () => import('../views/coach-worktable/recruit-posts'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '培训信息',
      keepAlive: true,
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/coach/publish-recruit',
    name: 'coachWorktablePublishRecruit',
    component: () => import('../views/coach-worktable/publish-recruit'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '发布培训',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/coach/learning-posts',
    name: 'coachWorktableLearningPosts',
    component: () => import('../views/coach-worktable/learning-posts'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '教学资讯',
      keepAlive: true,
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/coach/publish-article',
    name: 'coachWorktablePublishArticle',
    component: () => import('../views/coach-worktable/publish-article'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '发布文章',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/coach/publish-video',
    name: 'coachWorktablePublishVideo',
    component: () => import('../views/coach-worktable/publish-video'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '发布视频',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/coach/settings-profile',
    name: 'coachWorktableSettingsProfile',
    component: () => import('../views/coach-worktable/settings-profile'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '教练资料',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/invite-list',
    name: 'myWorktableInviteList',
    component: () => import('../views/coach-worktable/invite-list'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '推荐关系',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/account-disabled',
    name: 'myWorktableAccountDisabled',
    component: () => import('../views/coach-worktable/account-disabled'),
    meta: {
      title: '教练账号已被停用',
    },
  },
  {
    path: '/my-worktable/wallet',
    name: 'myWorktableWallet',
    component: () => import('../views/coach-worktable/wallet/home'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '我的钱包',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/wallet/extract',
    name: 'myWorktableWalletExtract',
    component: () => import('../views/coach-worktable/wallet/extract'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '提现',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/students',
    name: 'myWorktableStudents',
    component: () => import('../views/coach-worktable/students/students-list'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '我的学员',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/students/details',
    name: 'myWorktableStudentsDetails',
    component: () => import('../views/coach-worktable/students/student-details'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '学员上课详情',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/students/buy-list',
    name: 'myWorktableStudentsBuyList',
    component: () => import('../views/coach-worktable/students/buy-list'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '学员购买记录',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/message',
    name: 'myWorktableMessage',
    component: () => import('../views/coach-worktable/message/message-list'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '工作台消息',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/order',
    name: 'myWorktableOrder',
    component: () => import('../views/coach-worktable/order/order-list'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '订单',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/order/details',
    name: 'myWorktableOrderDetails',
    component: () => import('../views/coach-worktable/order/order-details'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '订单详情',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
  {
    path: '/my-worktable/order/refund-details',
    name: 'myWorktableOrderRefundDetails',
    component: () => import('../views/coach-worktable/order/refund-order-details'),
    beforeEnter: verifyIdentity,
    meta: {
      title: '退款订单详情',
      page: {
        navigationBarDefaultHomePath: '/my-worktable/coach',
      },
    },
  },
]
