// # 公共的业务函数放置
import router from '@/router'
import axios from 'axios'
import { localProxyStorage } from '@/utils/storage'
import { ossURL } from '@/config'
import { logout } from '@/api/auth-server'
// import { clearStorage, isIOS, isWeChat } from "@/utils";
import { clearStorage } from '@/utils'
import useKeepAliveStore from '@/store/keepAlive'
import { validate } from '@/utils/validate'
import dayjs from 'dayjs'
import { LoginPopup } from '@/views/login/components/LoginPopup'

// // 去登录
// export const toLogin = () => {
//   const route = router.currentRoute.value;
//   // let backUrl = route.fullPath; // 有问题暂时不用
//   let backUrl = location.pathname + location.search;
//
//   // backUrl 不能是登录页
//   let blacklist = ["/login"];
//   if (blacklist.includes(location.pathname)) {
//     backUrl = "/";
//   }
//
//   router.push({
//     name: "login",
//     query: {
//       shareId: route.query.shareId || undefined,
//       backUrl: encodeURIComponent(backUrl),
//     },
//   });
// };

export const toLogin = () => {
  LoginPopup()
}

// 退出当前登录
export const signOut = () => {
  // 直接先清理本地信息，后再通知服务端
  clearStorage()
  logout().then()
  router.push({ name: 'home' })
}

/**
 * 是否已登陆
 */
export const isLogin = () => {
  return localProxyStorage?.user?.authToken ? true : false
}

// 高德获取位置
export const getUserLocation = () => {
  return axios.get('https://restapi.amap.com/v3/ip?key=80912f26af58c13f0cfd7d4ff10dfb1e')
}

// 高德经纬度查询
export const getLocationDatils = (longitude, latitude) => {
  return axios.get(
    `https://restapi.amap.com/v3/geocode/regeo?ip=223.156.143.192&key=80912f26af58c13f0cfd7d4ff10dfb1e&location=${longitude},${latitude}&extensions=all&batch=false`,
    // `https://restapi.amap.com/v3/geocode/regeo?ip=223.156.143.192&key=80912f26af58c13f0cfd7d4ff10dfb1e&location=116.84,27.72&extensions=all&batch=false`
  )
}

// 高德关键字搜索
export const getLocationInfo = (params) => {
  var url =
    // "https://restapi.amap.com/v3/place/text?key=80912f26af58c13f0cfd7d4ff10dfb1e&offset=10&page=1&extensions=all";
    'https://restapi.amap.com/v3/assistant/inputtips?key=80912f26af58c13f0cfd7d4ff10dfb1e&offset=10&page=1&datatype=poi'

  url = url + urlEncode(params, 3)
  //http://baidu.com?a=1&b=2
  return axios.get(url)
}

// 拼接oss地址
export const ossURLJoin = (url) => {
  if (!url) return ''
  if (validate('networkPath', url)) {
    return url
  } else {
    let splicer = '/'
    if (url.substring(0, 1) === '/') {
      splicer = ''
    }
    return ossURL + splicer + url
  }
}

// 拼接oss地址(图片压缩)
export const ossURLCompress = (url) => {
  if (!url) return ''
  const urlStr = ossURLJoin(url)
  return urlStr + '?x-oss-process=image/interlace,1/format,jpg/quality,q_50'
}

// 解析文章的视频地址
export function parserVideoUrl(url) {
  let REGS = {
    youku: /v.youku.com/, // 优酷网
    tudou: /www.tudou.com/, // 土豆网
    ku6: /v.ku6.com/, // 酷6网
    56: /www.56.com/, // 56视频
    tencent: /v.qq.com/,
    xg: /www.ixigua.com/,
  }

  let parser = {
    youku(url) {
      let reg = new RegExp('https?://v.youku.com/v_show/id_([^/]+)(.html)', 'i')
      let res = url.match(reg)
      if (!res) return
      if (res) {
        return `https://player.youku.com/embed/${res[1]}`
      }
    },
    tencent(url) {
      let reg = new RegExp('([^/]+).html', 'i')
      let res = url.match(reg)
      if (!res) return
      if (res) {
        return `https://v.qq.com/txp/iframe/player.html?vid=${res[1]}`
      }
    },
    xg(url) {
      let reg = new RegExp('https?://www.ixigua.com/([^/]+)', 'i')
      let res = url.match(reg)
      if (!res) return
      if (res) {
        return `https://www.ixigua.com/iframe/${res[1]}`
      }
    },
  }

  let afterParserUrl = null

  for (let key in REGS) {
    if (REGS[key].test(url) && parser[key]) {
      afterParserUrl = parser[key](url)
      break
    }
  }

  return afterParserUrl
}

/**
 * param 将要转为URL参数字符串的对象
 * key URL参数字符串的前缀
 * encode true/false 是否进行URL编码,默认为true
 * idx ,循环第几次，用&拼接
 * return URL参数字符串
 */
const urlEncode = (param, idx, key, encode) => {
  if (param == null) return ''
  let paramStr = ''
  let t = typeof param
  if (t === 'string' || t === 'number' || t === 'boolean') {
    let one_is = idx < 3 ? '?' : '&'
    paramStr += one_is + key + '=' + (encode == null || encode ? encodeURIComponent(param) : param)
  } else {
    for (let i in param) {
      let k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i)
      idx++
      paramStr += urlEncode(param[i], idx, k, encode)
    }
  }
  return paramStr
}

export const getIsUserPost = (userId) => {
  if (!isLogin()) return false
  return userId === localProxyStorage.user.userId
}

// 加密手机号码
export const encryptTel = (tel) => {
  if (!tel) return
  let reg = /^(\d{3})\d{4}(\d{4})$/
  return tel.replace(reg, '$1****$2')
}

export function iosRefresh(to, form, next) {
  // 暂无用处
  // 解决ios端第一次进入页面，微信分享失效的bug
  // if (isIOS() && isWeChat() && to.path !== location.pathname) {
  //   console.log("刷新了，告诉你一下");
  //   // location.assign(to.fullPath);
  //   // window.location.href = `${window.location.origin}${to.fullPath}`;
  //   return;
  // }

  next()
}

export const toAuthorHome = (mappingId, identityType) => {
  const keepAliveStore = useKeepAliveStore()

  let data = {
    coach: '/coach/details/',
    venues: '/shop/details/',
  }

  // 由于详情页做了页面缓存，这里跳文章就要把缓存清理了
  keepAliveStore.removeKeepAlive('coachDetails')
  keepAliveStore.removeKeepAlive('shopDetails')

  router.push({
    path: data[identityType] + mappingId,
  })
}
/**
 * dataurl: base64
 * filename: 设置文件名称
 */
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {
    type: mime,
  })
}

// 获取静态资源的文件的完整地址
export const getOssURL = (url) => {
  if (!url) return ''
  if (validate('networkPath', url)) {
    return url
  } else {
    let splicer = '/'
    if (url.substring(0, 1) === '/') {
      splicer = ''
    }
    return ossURL + splicer + url
  }
}

export const formatCommentTime = (val) => {
  if (!val) return

  let time = dayjs(val).startOf('date').unix()
  let todayTime = dayjs().startOf('date').unix()
  let diff = time - todayTime

  if (diff === 0) {
    return '今天 ' + dayjs(val).format('HH:mm')
  }

  if (diff === -86400) {
    return '昨天 ' + dayjs(val).format('HH:mm')
  }

  return dayjs(val).format('YYYY-MM-DD HH:mm')
}
