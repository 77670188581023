import { isLogin } from '@/common'

const verifyLogin = (to, form, next) => {
  if (isLogin()) {
    next('/')
    return
  }
  next()
}

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/sign-in'),
    beforeEnter: verifyLogin,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import('../views/login/sign-up'),
    beforeEnter: verifyLogin,
    meta: {
      title: '注册',
      keepAlive: true,
    },
  },
  {
    path: '/sign-up-success',
    name: 'signUpSuccess',
    component: () => import('../views/login/sign-up-success'),
    meta: {
      title: '注册成功',
    },
  },
  {
    path: '/account/password-reset',
    name: 'passwordReset',
    component: () => import('../views/account/password-reset'),
    meta: {
      title: '找回密码',
    },
  },
  {
    path: '/account/password-reset-success',
    name: 'passwordResetSuccess',
    component: () => import('../views/account/password-reset-success'),
    meta: {
      title: '找回密码',
    },
  },
  {
    path: '/account/coach-update-list',
    name: 'coachUpdateList',
    component: () => import('../views/account/coach-update-list'),
    meta: {
      title: '教练资料',
    },
  },
  {
    path: '/account/shop-update-list',
    name: 'shopUpdateList',
    component: () => import('../views/account/shop-update-list'),
    meta: {
      title: '场馆资料',
    },
  },
  {
    path: '/account/coach-apply-form',
    name: 'coachApplyForm',
    component: () => import('../views/account/coach-apply-form'),
    meta: {
      title: '入驻教练',
      keepAlive: true,
    },
  },
  {
    path: '/account/shop-apply-form',
    name: 'shopApplyForm',
    component: () => import('../views/account/shop-apply-form'),
    meta: {
      title: '入驻场馆',
      keepAlive: true,
      requiresAuth: true,
    },
  },
  {
    path: '/account/settings',
    name: 'accountSettings',
    component: () => import('../views/account/account-settings'),
    meta: {
      title: '我的账号',
      requiresAuth: true,
    },
  },
  {
    path: '/account/modify-password',
    name: 'modifyPassword',
    component: () => import('../views/account/modify-password'),
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '/account/binding-phone',
    name: 'bindingPhone',
    component: () => import('../views/account/binding-phone'),
    meta: {
      title: '绑定手机号',
    },
  },
]
