<template>
  <div class="i-field">
    <div class="label">
      <img class="icon" :src="icon" alt="icon" />
    </div>
    <input
      class="input"
      :type="type"
      :value="modelValue"
      @input="setValue"
      @blur="valueBlur"
      :placeholder="placeholder"
      autocomplete="off"
      :maxlength="maxlength"
    />
    <slot name="right"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [String, Number],
        default: null,
      },
      placeholder: {
        type: String,
        default: '请输入',
      },
      type: {
        type: String,
        default: 'text',
      },
      leftIcon: {
        type: String,
        default: null,
      },
      maxlength: {
        type: String,
        default: null,
      },
    },
    setup(props, context) {
      const setValue = (e) => {
        context.emit('update:modelValue', e.target.value)
      }
      const valueBlur = (e) => {
        context.emit('blur', e.target.value)
      }

      const icon = require(`../../../assets/images/icon/${props.leftIcon}.png`)

      return {
        setValue,
        valueBlur,
        icon,
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/mixins/mixins';

  @include Icon('error-tip', 0.13rem, 0.13rem);
  .i-field {
    display: flex;
    height: 0.48rem;
    margin-top: 0.08rem;
    align-items: center;
    position: relative;

    .label {
      width: 0.41rem;
      padding: 0.05rem 0.16rem 0 0.06rem;
      position: absolute;
    }

    .icon {
      width: 0.24rem;
      height: 0.24rem;
    }

    .input {
      flex: 1;
      font-size: 0.16rem;
      color: #1a1b1d;
      padding: 0.12rem 0 0.12rem 0.41rem;
      outline: none;
      border: none;
      background-color: transparent;
      background-clip: content-box;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #ebebeb;

      &:focus {
        border-bottom-color: #f5b04c;
      }

      &::-webkit-input-placeholder {
        color: #d1d1d1;
      }

      &::-moz-placeholder {
        color: #d1d1d1;
      }

      &:-ms-input-placeholder {
        color: #d1d1d1;
      }

      &:-webkit-autofill {
        background-color: #fff !important;
      }
    }
  }
</style>
