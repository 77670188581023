export const isBoolean = (val) => typeof val === 'boolean'

export const isFunction = (val) => typeof val === 'function'

export const isNumber = (val) => typeof val === 'number'

export const isString = (val) => typeof val === 'string'

export const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]'

export const isArray = (val) => Object.prototype.toString.call(val) === '[object Array]'

export function isStringValid(str) {
  return typeof str === 'string' && str.trim() !== '' && str !== 'null' && str !== 'undefined'
}
