<template>
  <button class="ijl-goods-action-button" :class="buttonClassName" :style="buttonStyle">
    <slot>
      {{ text }}
    </slot>
  </button>
</template>

<script>
  export default { name: 'IjlGoodsActionButton' }
</script>

<script setup>
  import { computed } from 'vue'
  import { useParent } from '@/use/useRelation'

  let props = defineProps({
    text: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
  })

  const { parent, index } = useParent('IjlGoodsAction')

  const isFirst = computed(() => {
    return index.value === 0
  })

  const isLast = computed(() => {
    return index.value === parent.internalChildren.length - 1
  })

  const buttonClassName = computed(() => {
    return {
      'ijl-goods-action-button--first': isFirst.value,
      'ijl-goods-action-button--last': isLast.value,
    }
  })

  const buttonStyle = computed(() => {
    return {
      background: props.backgroundColor,
      color: props.color,
      borderColor: props.borderColor,
    }
  })
</script>

<style lang="scss" scoped>
  .ijl-goods-action-button {
    flex: 1;
    height: 0.4rem;
    font-weight: 500;
    font-size: 0.14rem;
    //border: none;
    border-radius: 0;
    color: #fff;
    padding: 0.1rem 0.25rem;
    background: linear-gradient(270deg, #ff6445 0%, #ff9b26 100%);
    cursor: pointer;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
  }

  .ijl-goods-action-button--first {
    margin-left: 0.05rem;
    border-right: none;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
  }

  .ijl-goods-action-button--last {
    margin-right: 0.05rem;
    border-left: none;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }
</style>
