<template>
  <ijiaolian-tabbar v-show="showTabBar" class="tabbar-bottom">
    <div class="tabbar">
      <div class="tabbar-border" :style="{ background: tarBarConfig.borderColor }"></div>
      <div
        v-for="(item, index) in tarBarList"
        :key="item"
        class="tabbar__item"
        @click="toggleTab(item, index)"
      >
        <div class="tabbar__bd">
          <div class="tabbar__icon">
            <img :src="getIconPath(item, index)" alt="" />
          </div>
          <div class="tabbar__label" :style="labelStyle(item, index)">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="tabbar-placeholder" :style="{ height: height }" />
  </ijiaolian-tabbar>
</template>

<script setup>
  import { ref, reactive, watch, computed, inject } from 'vue'
  import layoutConfig from '@/layout/layout.config'
  import { useRoute, useRouter } from 'vue-router'

  defineProps({
    height: {
      type: String,
      default: '0.5rem',
    },
  })

  const setShowTabBar = inject('setShowTabBar')

  const route = useRoute()
  const router = useRouter()
  const selectedIndex = ref(0)
  const tarBarConfig = reactive(layoutConfig.tabBar)
  const tarBarList = ref(tarBarConfig.list)

  const showTabBar = computed(() => {
    let show = selectedIndex.value >= 0
    setShowTabBar(show)
    return show
  })

  const getIconPath = (item, index) => {
    return (
      (selectedIndex.value === index ? item.selectedIconPath || item.iconPath : item.iconPath) || ''
    )
  }

  const labelStyle = (item, index) => {
    return {
      color: selectedIndex.value === index ? tarBarConfig.selectedColor : tarBarConfig.color,
    }
  }

  const setSelectedIndex = () => {
    const index = tarBarList.value.findIndex((item) => route.path === item.pagePath)
    selectedIndex.value = index
  }

  const toggleTab = (item, index) => {
    if (route.path !== item.pagePath) {
      selectedIndex.value = index
      router.push(item.pagePath)
    }
  }

  watch(
    route,
    () => {
      setSelectedIndex()
    },
    {
      immediate: true,
    },
  )
</script>

<style lang="scss" scoped>
  .tabbar-bottom {
    position: fixed;
    left: var(--window-left);
    right: var(--window-right);
    bottom: 0;
    z-index: 998;
  }

  .tabbar-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    transform: scaleY(0.5);
  }

  .tabbar {
    display: flex;
    background-color: rgb(248, 248, 248);
    backdrop-filter: none;
    position: fixed;
    bottom: 0;
    left: var(--window-left);
    right: var(--window-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    user-select: none;
  }

  .tabbar__item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .tabbar__bd {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 0.6rem;
      cursor: pointer;
    }

    .tabbar__icon {
      width: 0.28rem;
      height: 0.28rem;
      position: relative;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tabbar__label {
      position: relative;
      text-align: center;
      color: rgb(122, 126, 131);
      font-size: 0.1rem;
      line-height: normal;
      //margin-top: 0.03rem;
    }
  }

  .tabbar-placeholder {
    width: 100%;
  }
</style>
