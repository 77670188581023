<template>
  <ijiaolian-app id="ijiaolian-app" :class="{ 'app-showtabbar': showTabBar }">
    <layout :keepAliveInclude="KeepAliveStore.keepAlive" />
    <tab-bar />
  </ijiaolian-app>
</template>

<script setup>
  import { ref, watch, provide } from 'vue'
  import { useRoute } from 'vue-router'
  import Layout from '@/layout/components/Layout'
  import TabBar from '@/layout/components/TabBar'
  import useKeepAliveStore from '@/store/keepAlive'
  import { reqIpLocation } from '@/api/common'
  import { sessionProxyStorage, localProxyStorage } from '@/utils/storage'
  import { useChildren } from '@/use/useRelation'

  const route = useRoute()
  const showTabBar = ref(false)
  const geo = ref({})
  let KeepAliveStore = useKeepAliveStore()

  const { linkChildren } = useChildren('IjlAPP')

  const getGeolocation = async () => {
    const { data } = await reqIpLocation()
    if (data.status === '1' && typeof data.adcode === 'string' && data.adcode !== '') {
      // 解决高德地图返回省的编码情况
      if (data.adcode.indexOf('0000') > -1) {
        data.adcode = data.adcode.substring(0, 2) + '0100'
      }
      geo.value = { adcode: data.adcode, city: data.city }
    } else {
      geo.value = { adcode: '440100', city: '广州市' }
    }

    sessionProxyStorage.curGeolocation = geo.value
    if (!localProxyStorage.selectedGeolocation) {
      localProxyStorage.selectedGeolocation = geo.value
    }
  }

  const updateKeepAlive = (to) => {
    if (to.meta.keepAlive) {
      KeepAliveStore.updateKeepAlive(to.name)
    }
  }

  const initializeApp = () => {
    getGeolocation()
  }

  provide('setShowTabBar', (val) => {
    showTabBar.value = val
  })

  watch(route, updateKeepAlive, { immediate: true })

  linkChildren({ geo })

  initializeApp()
</script>

<style scoped>
  ijiaolian-app {
    display: block;
    width: 100%;
    background-color: #f8f7f7;
    margin: 0 auto;
  }
</style>
