// # 环境变量

const networkProtocol = window.location.protocol

// # 域名地址
export const baseURL = networkProtocol + process.env['VUE_APP_BASE_URL']

// # OSS地址
export const ossURL = networkProtocol + process.env['VUE_APP_OSS_URL']

// # 省市区数据JSON文件地址
export const districtsURL = ossURL + '/gaode_dict.json'

export const apiURL = networkProtocol + process.env['VUE_APP_API_URL']

// # OSS临时文件域名
export const ossTempURL = networkProtocol + process.env['VUE_APP_OSS_TEMP_URL']

// 公众号 appid
export const appid = process.env['VUE_APP_APPID']

// 小程序环境
export const miniprogramEnv = process.env['VUE_APP_MINIPROGRAM_ENV']
