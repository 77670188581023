<template>
  <div>
    <template v-if="loading">
      <div class="ijl-skeleton" :class="{ 'is-animated': animated }">
        <div v-for="i in count" :key="i" class="ijl-skeleton-item">
          <slot name="skeleton" />
        </div>
      </div>
    </template>
    <template v-else>
      <slot name="content" />
    </template>
  </div>
</template>

<script>
  export default { name: 'ijlSkeleton' }
</script>

<script setup>
  defineProps({
    width: {
      type: String,
      default: '100%',
    },
    animated: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Number,
      default: 1,
    },
  })
</script>

<style lang="scss">
  .ijl-skeleton {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .is-animated {
    .ijl-skeleton-row,
    .ijl-skeleton-image {
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size: 400% 100%;
      animation: skeleton-loading 1.4s ease infinite;
    }
  }

  .ijl-skeleton-item {
    flex: 1;
    margin-bottom: 0.1rem;
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
</style>
