<template>
  <ijiaolian-layout>
    <ijiaolian-content>
      <ijiaolian-main>
        <router-view v-slot="{ Component }">
          <keep-alive :include="keepAliveInclude" :max="10">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </ijiaolian-main>
    </ijiaolian-content>
  </ijiaolian-layout>
</template>

<script setup>
  import { isIOS } from '@/utils'

  defineProps({
    keepAliveInclude: {
      type: Array,
      default: function () {
        return []
      },
    },
  })

  let styleObj
  let lastClientHeight = document.documentElement.clientHeight

  const updateCssVar = (name, value) => {
    if (!styleObj) {
      styleObj = document.documentElement.style
    }
    styleObj.setProperty(name, value)
  }

  const checkMaxWidth = () => {
    const windowWidth = document.body.clientWidth

    const maxWidth = 480
    let marginWidth

    if (windowWidth > maxWidth) {
      marginWidth = (windowWidth - maxWidth) / 2
    } else {
      marginWidth = 0
    }

    updateCssVar('--window-left', marginWidth + 'px')
    updateCssVar('--window-right', marginWidth + 'px')
  }

  const checkSafeArea = () => {
    const windowHeight = document.documentElement.clientHeight

    if (isIOS()) {
      let diffHeight = windowHeight - lastClientHeight
      if (diffHeight < 0) {
        diffHeight = 0
      }

      updateCssVar('--wx-safe-area-inset-bottom', diffHeight + 'px')
      lastClientHeight = windowHeight
    }
  }

  const initMaxWidth = () => {
    window.addEventListener('resize', () => {
      checkMaxWidth()
      checkSafeArea()
    })
  }

  const layoutInit = () => {
    initMaxWidth()
    checkMaxWidth()
  }

  layoutInit()
</script>

<style scoped>
  ijiaolian-content {
    display: block;
    width: 100%;
    min-height: 100vh;
  }

  ijiaolian-main {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
