<template>
  <router-view />
</template>

<script setup>
  import { banWeChatSetFontSize } from '@/utils'

  // 禁止微信浏览器设置字体大小
  banWeChatSetFontSize()
</script>

<style lang="scss" scoped></style>
