import { localProxyStorage } from '@/utils/storage'

const injectUserInfo = () => {
  if (window.sensors && localProxyStorage.user) {
    window.sensors.loginWithKey('$identity_login_id', localProxyStorage.user.userId)
  }
}

export default {
  injectUserInfo,
}
