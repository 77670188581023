<template>
  <ijiaolian-page-head>
    <div
      class="page-head"
      :style="{ background: bgColor, color: textColor }"
      :class="pageHeadClass"
    >
      <div class="page-head-hd">
        <div class="page-head-btn" @click="onBack">
          <van-icon size="0.20rem" name="arrow-left" />
        </div>
      </div>
      <div class="page-head-bd" v-if="!searchInput">
        <div class="page-head-title" :style="{ opacity: type === 'transparent' ? 0 : 1 }">
          <img v-if="titleImage" :src="titleImage" class="page-head__title_image" />
          <template v-else>
            {{ title }}
          </template>
        </div>
      </div>
      <div v-if="searchInput" class="page-head-search" :style="searchStyle">
        <div
          :style="{ color: searchInput.placeholderColor }"
          :class="[
            'page-head-search-placeholder',
            `page-head-search-placeholder-${focus || showPlaceholder ? 'left' : searchInput.align}`,
          ]"
          v-text="showPlaceholder ? '' : searchInput.placeholder"
        />
        <div class="page-head-search-input">
          <input
            v-model="searchText"
            :disabled="searchInput.disabled"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @input="onInput"
            type="search"
            class="input"
          />
        </div>
      </div>
      <div class="page-head-ft" :style="{ opacity: type === 'transparent' ? 0 : 1 }">
        <slot />
      </div>
      <div class="head-border" :style="{ backgroundColor: lineColor }"></div>
    </div>
    <div v-if="type !== 'transparent'" class="placeholder"></div>
  </ijiaolian-page-head>
</template>

<script>
  export default { name: 'PageHead' }
</script>

<script setup>
  import { ref, computed, nextTick, watch, onBeforeUnmount } from 'vue'
  import { useRouter } from 'vue-router'
  import useTransparent from '@/layout/components/Page/transparent'
  import hexToRgba from '@/layout/utils/color'

  const router = useRouter()

  const props = defineProps({
    type: {
      default: 'default',
      validator(value) {
        return ['default', 'transparent'].indexOf(value) !== -1
      },
    },
    title: {
      type: String,
      default: '',
    },
    titleImage: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default() {
        return '#000'
      },
    },
    textColor: {
      type: String,
      default: '#fff',
    },
    defaultHomePath: {
      type: String,
      default: '/',
    },
    searchInput: {
      type: [Object, Boolean],
      default() {
        return false
      },
    },
    borderColor: {
      type: String,
      default: '#eee',
    },
    coverage: {
      type: Number,
      default: 132,
    },
  })

  const searchText = ref('')
  const focus = ref(false)
  const showPlaceholder = ref(false)

  const { onTransparentModel, offTransparentModel } = useTransparent({
    offset: props.coverage, // 滚动距离
    backgroundColor: props.backgroundColor,
    textColor: props.textColor,
    borderColor: props.borderColor,
  })

  watch(
    () => props.type,
    (newVal) => {
      if (newVal === 'transparent') {
        nextTick(() => {
          onTransparentModel()
        })
      } else {
        offTransparentModel()
      }
    },
    {
      immediate: true,
    },
  )

  const pageHeadClass = computed(() => {
    return {
      'page-head-transparent': props.type === 'transparent',
    }
  })

  const bgColor = computed(() => {
    if (props.type === 'transparent') {
      const { r, g, b } = hexToRgba(props.backgroundColor)
      return `rgba(${r},${g},${b},0)`
    }
    return props.backgroundColor
  })

  const lineColor = computed(() => {
    if (props.type === 'transparent') {
      const { r, g, b } = hexToRgba(props.borderColor)
      return `rgba(${r},${g},${b},0)`
    }
    return props.borderColor
  })

  const searchStyle = computed(() => {
    return {
      'border-radius': props.searchInput.borderRadius,
      'background-color': props.searchInput.backgroundColor,
    }
  })

  const onInputFocus = () => {
    focus.value = true
  }

  const onInputBlur = () => {
    focus.value = false
  }

  const onInput = (event) => {
    showPlaceholder.value = !!event.target.value
  }

  const onBack = () => {
    // 注意 history.state 值是 vue-route库 维护提供的
    // 项目路由必须采用的是 history 理由模式
    let back = window.history.state.back
    if (back) {
      router.go(-1)
    } else {
      router.push(props.defaultHomePath)
    }
  }

  onBeforeUnmount(() => {
    offTransparentModel()
  })
</script>

<style lang="scss" scoped>
  .page-head {
    position: fixed;
    top: 0;
    left: var(--window-left);
    right: var(--window-right);
    height: 0.44rem;
    height: calc(0.44rem + constant(safe-area-inset-top));
    height: calc(0.44rem + env(safe-area-inset-top));
    padding: 0.07rem 0.03rem;
    padding-top: calc(0.07rem + constant(safe-area-inset-top));
    padding-top: calc(0.07rem + env(safe-area-inset-top));
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 998;
    color: #000;
    background-color: #f1f1f1;
    transition-property: all;
    user-select: none;
    pointer-events: auto;
  }

  .page-head-hd {
    display: flex;
    align-items: center;
    font-size: 0.16rem;
  }

  .page-head-bd {
    position: absolute;
    left: 0.7rem;
    right: 0.7rem;
    min-width: 0;
  }

  .page-head-ft {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .page-head-title {
    font-size: 0.16rem;
    font-weight: 700;
    line-height: 0.3rem;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .page-head__title_image {
    width: auto;
    height: 0.26rem;
    vertical-align: middle;
  }

  .page-head-search {
    position: relative;
    display: flex;
    flex: 1;
    margin: 0 0.02rem;
    line-height: 0.3rem;
    font-size: 0.16rem;
    border-radius: 0.06rem;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
  }

  .page-head-search-placeholder {
    position: absolute;
    max-width: 100%;
    height: 100%;
    padding-left: 0.34rem;
    overflow: hidden;
    word-break: keep-all;
    white-space: pre;
    color: rgb(204, 204, 204);
    pointer-events: none;

    &::before {
      content: '🔍️';
      position: absolute;
      top: 0;
      left: 0.02rem;
      width: 0.3rem;
      font-size: 0.2rem;
      display: block;
      text-align: center;
    }
  }

  .page-head-search-placeholder-right {
    right: 0;
  }

  .page-head-search-placeholder-center {
    left: 50%;
    transform: translateX(-50%);
  }

  .page-head-search-input {
    display: flex;
    flex: 1;

    input {
      width: 100%;
      height: 100%;
      color: black;
      padding-left: 0.34rem;
      border: none;
      outline: none;
    }
  }

  .page-head-btn {
    position: relative;
    width: auto;
    margin: 0 0.02rem;
    word-break: keep-all;
    white-space: pre;
    cursor: pointer;
  }

  .page-head-transparent .page-head-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  .placeholder {
    width: 100%;
    height: 0.44rem;
    height: calc(0.44rem + constant(safe-area-inset-top));
    height: calc(0.44rem + env(safe-area-inset-top));
    background-color: #efeff4;
  }

  .head-border {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    //background-color: rgba(0, 0, 0, 0.33);
    transform: scaleY(0.5);
  }
</style>
