import { createApp, reactive, getCurrentInstance } from 'vue'

export function usePopupState() {
  const state = reactive({
    show: false,
  })

  const open = (props) => {
    Object.assign(state, props)
    toggle(true)
  }

  const close = () => toggle(false)
  const toggle = (show) => {
    state.show = show
  }

  const instance = getCurrentInstance()
  if (instance) {
    Object.assign(instance.proxy, { open, close, toggle })
  }

  return {
    open,
    close,
    toggle,
    state,
  }
}

export function mountComponent(component) {
  const app = createApp(component)
  const root = document.createElement('div')

  document.body.appendChild(root)

  return {
    instance: app.mount(root),
    unmount() {
      app.unmount()
      document.body.removeChild(root)
    },
  }
}
