export default [
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/search'),
    meta: {
      title: '搜索',
    },
  },
  {
    path: '/search-result',
    name: 'searchResult',
    component: () => import('../views/search/search-result'),
    meta: {
      title: '找教练',
      keepAlive: true,
    },
  },
  {
    path: '/select-city',
    name: 'selectCity',
    meta: {
      title: '切换城市',
    },
    component: () => import('../views/search/select-city'),
  },
]
