// # 鉴权登录注册服务

import http from '@/utils/axios'

// 账号密码登录
export const loginByPassword = (params) => {
  return http.post('/auth-server/api/authLogin/v1/loginByPassword/nL', params)
}

// 短信验证码登录
export const loginByVerifyCode = (params) => {
  return http.post('/auth-server/api/authLogin/v1/loginByVerifyCode/nL', params)
}

// 图形验证码
export const graphicCaptchaURL = '/proxy/auth-server/api/verifyCode/v1/getImageCode/nL'

// 修复密码，发送验证码
export const sendBackPasswordVerifyCode = (params) => {
  return http.post('/auth-server/api/verifyCode/v1/sendBackPasswordVerifyCode/nL', params)
}

// 发送登录 or 注册验证码
export const sendVerifyCode = (params) => {
  return http.post('/auth-server/api/verifyCode/v1/sendVerifyCode/nL', params)
}

// 注册账号
export const sendRegistration = (params) => {
  return http.post('/auth-server/api/authLogin/v1/userMobileVerifyRegister/nL', params)
}

// 退出登录
export const logout = () => {
  return http.post('/auth-server/api/authLogin/v1/logout/nL')
}

// 使用微信 uid 登录账号
export const weChatLogin = (params) => {
  return http.post('/auth-server/api/authLogin/v1/userWeChatLogin/nL', params)
}
