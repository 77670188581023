import LoginPopupComponent from './LoginPopup'
import { mountComponent, usePopupState } from '@/components/utils/mount-component'
import { isWeChatEnv } from '@/utils'
import { sessionProxyStorage } from '@/utils/storage'
import { toWxAuth } from '@/utils/weChat/webPageAuth'

let instance = null
let loginPopupPromise = null

function initInstance() {
  const Wrapper = {
    setup() {
      const { state, toggle } = usePopupState()

      const onUpdateShow = (show) => {
        if (!show) loginPopupPromise = null
        toggle(show)
      }

      return () => (
        <div class="login-popup">
          <LoginPopupComponent {...state} onUpdate:show={onUpdateShow} />
        </div>
      )
    },
  }

  ;({ instance } = mountComponent(Wrapper))
}

function getBackUrl() {
  return location.pathname + location.search || '/'
}

export function LoginPopup(options) {
  if (!loginPopupPromise) {
    loginPopupPromise = new Promise((resolve, reject) => {
      sessionProxyStorage.backUrl = getBackUrl()

      if (isWeChatEnv() && !sessionProxyStorage.weChatUId) {
        toWxAuth()
        return
      }

      if (!instance) initInstance()

      let props = Object.assign({}, options, {
        callback: (action, params) => {
          ;(action === 'confirm' ? resolve : reject)(params)
        },
      })

      instance.open(props)
    })
  }

  return loginPopupPromise
}

LoginPopup.close = () => {
  if (instance) {
    instance.toggle(false)
    loginPopupPromise = null
  }
}
