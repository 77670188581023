export default [
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help/help'),
    meta: {
      title: '帮助中心',
    },
  },
  {
    path: '/help/feedback',
    name: 'helpFeedback',
    component: () => import('../views/help/feedback'),
    meta: {
      title: '发布需求',
    },
  },
]
