// # 放工具函数 业务函数禁止放这边
import { sessionProxyStorage } from '@/utils/storage'

/* eslint-disable */
// 禁止微信浏览器设置网页字体大小
export const banWeChatSetFontSize = function () {
  function handleFontSize() {
    WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on("menu:setfont", () => {
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    });
  }

  if (typeof WeixinJSBridge === "object" && typeof WeixinJSBridge.invoke === "function") {
    handleFontSize();
  } else if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
  }
};
/* eslint-enable */

/**
 * 判断是否为微信浏览器
 * */
export const isWeChat = () => {
  return /MicroMessenger/i.test(window.navigator.userAgent)
}
/**
 * 判断是否为企微
 * */
export const isQyWeChat = () => {
  //获取user-agaent标识头
  let ua = window.navigator.userAgent.toLowerCase()
  //判断ua和微信浏览器的标识头是否匹配
  if (ua.match(/micromessenger/i) == 'micromessenger' && ua.match(/wxwork/i) == 'wxwork') {
    return true
  } else {
    return false
  }
}

// 是否在微信环境中，并且不属于企业微信浏览器环境
export function isWeChatEnv() {
  // 判断是否在微信环境中
  const userAgent = navigator.userAgent.toLowerCase()
  const isWeChat = userAgent.includes('micromessenger')

  // 判断是否为企业微信浏览器环境
  const isEnterpriseWeChat = userAgent.includes('wxwork')

  // 返回判断结果
  return isWeChat && !isEnterpriseWeChat
}

/**
 * 是否是苹果系统
 * */
export const isIOS = function () {
  return /ip(hone|od|ad)/i.test(navigator.userAgent)
}

/**
 * 是否是安卓系统
 * */
export const isAndroid = function () {
  return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
}

// uuid 生成函数
export function uuid() {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  return s.join('')
}

export function clearStorage() {
  let shareId = sessionProxyStorage?.shareId
  localStorage.clear()
  sessionStorage.clear()

  if (shareId) {
    sessionProxyStorage.shareId = shareId
  }
}

export function byteConvert(size) {
  if (!size) return ''
  let num = 1024 //byte
  if (size < num) return size + 'B'

  if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + 'KB'
  }

  if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + 'MB'
  }

  if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + 'G'
  }

  return (size / Math.pow(num, 4)).toFixed(2) + 'T'
}

export const sliceStr = (str, start, end) => {
  if (typeof str === 'string') {
    return str.slice(start, end)
  }
  return ''
}

// 对数字的小数点处理，例如：（12.00） 返回 12；（12.05） 返回 12.05
export function numberRP(num) {
  if (typeof num === 'number') {
    return Number(num.toFixed(2))
  }
  return ''
}

// 金额检验
export function verificationAmount(amount) {
  // 如果输入非数字，则替换为''
  amount = amount.replace(/[^\d.]/g, '')
  // 第一个位如果是 . 则替换为 0.
  amount = amount.replace(/^\./g, '0.')
  // 保证只有出现一个.而没有多个.
  amount = amount.replace(/\.{2,}/g, '.')
  //前两位不能是0加数字
  amount = amount.replace(/^0\d[0-9]*/g, '')
  // 保证.只出现一次，而不能出现两次以上
  amount = amount.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  // 只能输入两位小数
  amount = amount.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  return amount
}

// TODO: 这个没用等待删除
export const teachTypeFilter = (item) => {
  const obj = {
    1: '私教1对1',
    2: '小班1对2',
    3: '小班1对4',
    4: '亲子班',
  }
  return obj[item]
}

export const getBase64Image = (imgUrl) => {
  return new Promise((resolve) => {
    if (!imgUrl) {
      resolve()
    }

    const MAX_WIDTH = 800
    const canvas = document.createElement('canvas')
    let image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = imgUrl

    image.onload = () => {
      // TODO: 浏览器都对画布元素的高度/宽度有限制 如果超出限制 canvas.toDataURL 生成出来的数据会为 data:, 空
      // 这里做了限制
      if (image.width > MAX_WIDTH) {
        canvas.width = MAX_WIDTH
        canvas.height = (image.height * MAX_WIDTH) / image.width
      } else {
        canvas.width = image.width
        canvas.height = image.height
      }

      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
      const ext = image.src
        .substring(image.src.lastIndexOf('.') + 1)
        .toLowerCase()
        .split('?')

      const dataURL = canvas.toDataURL('image/' + ext[0])
      resolve(dataURL)
    }

    image.onerror = () => {
      console.log('⭕：', `图片加载失败，路径为[${imgUrl}]`)
      resolve()
    }
  })
}

// 防抖
// 不管点击多次此按钮，只执行最后一次
export function debounce(fun, time) {
  let timer
  return function () {
    clearTimeout(timer)
    let args = arguments
    timer = setTimeout(() => {
      fun.apply(this, args)
    }, time)
  }
}

// 节流
// 节流是一段时间内执行一次
export function throttle(fun, time) {
  let t1 = 0 //初始时间
  return function () {
    let t2 = new Date() //当前时间
    if (t2 - t1 > time) {
      fun.apply(this, arguments)
      t1 = t2
    }
  }
}

/**
 * base64转file
 * @param { base64 } base64
 * @param { string } filename 转换后的文件名
 * @return {{}} file
 */
export const base64ToFile = (base64, filename) => {
  let arr = base64.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1] // 图片后缀
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, { type: mime })
}

/**
 * 获取字符串长度,表情长度为 1
 * @param str
 * @return {number}
 */

export function getStringLength(str) {
  if (typeof str !== 'string') return 0
  return [...str].length
}

/**
 * 获取浏览器环境信息
 */
export function getBrowserEnv() {
  const userAgent = navigator.userAgent.toLowerCase()
  const isMicromessenger = userAgent.indexOf('micromessenger') !== -1

  // 判断是否在企业微信浏览器环境中
  if (isMicromessenger && userAgent.indexOf('wxwork') !== -1) {
    return 'wxwork'
  }

  // 判断是否在微信小程序环境中
  if (isMicromessenger && userAgent.indexOf('miniprogram') !== -1) {
    return 'miniprogram'
  }

  // 判断是否在微信浏览器环境中
  if (isMicromessenger) {
    return 'wechat'
  }

  // 不在上述环境中，可能是普通浏览器环境
  return 'browser'
}
