<template>
  <ijiaolian-page-loading>
    <div class="page-loading">
      <slot>
        <van-loading type="spinner" vertical>加载中...</van-loading>
      </slot>
    </div>
  </ijiaolian-page-loading>
</template>

<script>
  export default { name: 'PageLoading' }
</script>

<style scoped>
  ijiaolian-page-loading .page-loading {
    position: fixed;
    height: 100%;
    left: var(--window-left);
    right: var(--window-right);
    z-index: 999;
    //display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
</style>
