<template>
  <div class="ijl-goods-action-icon">
    <div class="ijl-goods-action-icon__icon"><slot /></div>
    {{ text }}
  </div>
</template>

<script>
  export default {
    name: 'IjlGoodsActionIcon',
  }
</script>

<script setup>
  defineProps({
    text: {
      type: String,
      default: '',
    },
  })
</script>

<style lang="scss" scoped>
  .ijl-goods-action-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0.48rem;
    height: 100%;
    color: #646566;
    font-size: 0.1rem;
    line-height: 1;
    text-align: center;
    background-color: #fff;
    cursor: pointer;

    &:active {
      background-color: #f2f3f5;
    }
  }

  .ijl-goods-action-icon__icon {
    position: relative;
    width: 1em;
    //margin: 0 auto 0.05rem;
    margin: 0 0.15rem 0.04rem 0.15rem;
    color: #323233;
    font-size: 18px;
  }
</style>
