// # 教练场馆服务

import http from '@/utils/axios'

// 申请场馆角色账号
export const applyShopAccount = (params) => {
  return http.post('/coach-server/api/venues/v1/editOneVenues', params)
}

// 编辑场馆审核记录--基本信息
export const editVenuesBaseInfo = (params) => {
  return http.post('/coach-server/api/venuesCheckRecord/v1/editVenuesCheckRecordBaseInfo', params)
}
// 编辑场馆审核记录--资质信息
export const editVenuesQualificationInfo = (params) => {
  return http.post(
    '/coach-server/api/venuesCheckRecord/v1/editVenuesCheckRecordQualificationInfo',
    params,
  )
}

// 获取经营/授课类型列表
export const getCategoriesList = (params) => {
  return http.post('/coach-server/api/categories/v1/getCategoriesList/nL', params)
}

// 申请教练角色账号
export const applyCoachAccount = (params) => {
  return http.post('/coach-server/api/coach/v1/editCoach', params)
}

// 修改教练入驻审核基本信息
export const updateCoachBaseInfo = (params, isCoachIdentity = false) => {
  let url
  if (isCoachIdentity) {
    url = '/coach-server/api/coach/v1/updateCoachBaseInfo'
  } else {
    url = '/coach-server/api/coachCheckRecord/v1/updateCoachCheckRecordBaseInfo'
  }

  return http.post(url, params)
}

// 修改教练入驻审核--授课信息
export const updateCoachTeachingInfo = (params, isCoachIdentity = false) => {
  let url = '/coach-server/api/coachCheckRecord/v1/updateCoachCheckRecordTeachingInfo'
  if (isCoachIdentity) {
    url = '/coach-server/api/coach/v1/updateCoachTeachingInfo'
  }
  return http.post(url, params)
}

// 修改教练入驻审核--资质信息
export const updateCoachQualificationInfo = (params, isCoachIdentity = false) => {
  let url = '/coach-server/api/coachCheckRecord/v1/updateCoachCheckRecordQualificationInfo'
  if (isCoachIdentity) {
    url = '/coach-server/api/coach/v1/updateCoachQualificationInfo'
  }
  return http.post(url, params)
}

// 获取场馆详情页展示数据
export const getShopDetails = (params) => {
  return http.post('/coach-server/api/venues/v1/getVenuesById/nL', params)
}

// 校验用户是否教练
export const checkUserIsCoach = () => {
  return http.post('/coach-server/api/coach/v1/checkUserIsCoach')
}
// 获取教练详情
export const getCoachDetails = (params) => {
  return http.get('/coach-server/api/coach/v1/getCoachDetail/nL', { params })
}

// 获取教练列表
export const getCoachList = (params) => {
  return http.post('/coach-server/api/coach/v1/getCoachList/nL', params)
}
// 获取最新教练列表
export const reqNewestCoachList = (params) => {
  return http.post('/coach-server/api/coach/v1/getNewestCoachList/nL', params)
}

// 获取推荐教练列表
export const reqRecommendCoachList = (params) => {
  return http.post('/coach-server/api/coach/v1/getRecommendCoachList/nL', params)
}

// 获取场馆列表
export const getVenuesList = (params) => {
  return http.post('/coach-server/api/venues/v1/getVenuesList/nL', params)
}

// 获取用户的提交的教练信息
// export const getUserApplyCoachInfo = () => {
//   return http.post("/coach-server/api/examine/v1/getCoachLastOneExamine");
// };

// 获取教练最新分批审核记录
export const getCoachNewestBatchCheckRecord = (isCoachIdentity = false) => {
  let url = '/coach-server/api/coachCheckRecord/v1/getCoachNewestBatchCheckRecord'
  if (isCoachIdentity) {
    url = '/coach-server/api/coach/v1/getCoachBatchVO'
  }
  return http.post(url)
}

// 获取场馆最新分批审核
export const getVenuesCheckRecord = () => {
  return http.post('/coach-server/api/venuesCheckRecord/v1/getVenuesNewestBatchCheckRecord')
}
// 获取当前用户场馆ID
export const getVenuesIdByUserId = () => {
  return http.post('/coach-server/api/venues/v1/getVenuesIdByUserId')
}
// 新增教练审核且继承最新一次的记录
export const addCoachLastRecord = () => {
  return http.post('/coach-server/api/coachCheckRecord/v1/addCoachCheckByExtendsLastRecord')
}

// 新增场馆审核且继承最新一次的记录
export const addVenuesCheckByExtendsLastRecord = () => {
  return http.post('/coach-server/api/venuesCheckRecord/v1/addVenuesCheckByExtendsLastRecord')
}

export const getCoachVODetail = () => {
  return http.get('/coach-server/api/coach/v1/getCoachVODetail')
}

export const getCoachBaseInfo = () => {
  return http.get('/coach-server/api/coach/v1/getCoachBaseInfo')
}

export const checkUserIsRegisterCoach = () => {
  return http.post('/coach-server/api/coach/v1/checkUserIsRegisterCoach')
}

// 是否有提交教练入驻申请单
export const getIsSubmitCoachForm = () => {
  return http.post('/coach-server/api/coachCheckRecord/v1/isExistCoachCheckIng')
}

// 获取教练最新审核记录
export const getCoachNewestCheckRecord = () => {
  return http.post('/coach-server/api/coachCheckRecord/v1/getCoachNewestCheckRecord')
}

// 添加收藏
export const addCollection = (params) => {
  return http.post('/coach-server/api/coachCollection/v1/addCollection', params)
}

// 取消收藏
export const cancelCollection = (params) => {
  return http.post('/coach-server/api/coachCollection/v1/cancelCollection', params)
}

// 校验教练是否收藏
export const checkCoachIsCollection = (params) => {
  return http.get('/coach-server/api/coachCollection/v1/checkCoachIsCollection', { params })
}

// 获取教练收藏数量
export const getCoachCollectionCount = (params) => {
  return http.get('/coach-server/api/coachCollection/v1/getCoachCollectionCount/nL', { params })
}

// 获取教练收藏列表
export const getCoachCollectionList = (params) => {
  return http.post('/coach-server/api/coachCollection/v1/getCoachCollectionList', params)
}

// 获取教练 sku 配置数据
export const reqCoachSkuConfig = (params) => {
  return http.get('/coach-server/api/coachFeesSettings/v1/getcoachFeesSettingsSku/nL', { params })
}

// 获取教练详情 v2 版本
export const reqGetCoachDetailV2 = (params) => {
  return http.get('/coach-server/api/coach/v2/detail/nL', { params })
}
