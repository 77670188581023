<template>
  <van-dialog class="i-dialog" v-bind="attrs">
    <!--传递插槽-->
    <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]>
      <slot :name="key"></slot>
    </template>
  </van-dialog>
</template>

<script>
  export default { name: 'IDialog' }
</script>

<script setup>
  import { useAttrs } from 'vue'
  import { Dialog } from 'vant'
  const VanDialog = Dialog.Component

  const attrs = useAttrs()
</script>

<!-- 注意：样式使用的是全局 注意样式命名冲突 -->
<style lang="scss">
  .i-dialog {
    border-radius: 0.06rem;
    font-size: 0.16rem;

    .van-dialog__header {
      padding-top: 0.26rem;
      font-size: 0.18rem;
      font-weight: 600;
      color: #1f1f1f;
      padding-bottom: 0.1rem;
    }

    .van-dialog__footer {
      border-top: 1px solid #eeeeee;

      .van-dialog__confirm,
      .van-dialog__cancel {
        height: 0.4rem;
        font-size: 0.14rem;
      }

      .van-dialog__confirm {
        background: #ff9b26;
        color: #fff;
      }

      .van-dialog__cancel {
        color: #616568;
      }
    }
  }
</style>
