<template>
  <Popup
    v-model:show="popupShow"
    position="bottom"
    class="LoginPopup"
    closeable
    @close="closePopup"
  >
    <div class="popup-content">
      <div class="popup-content-hd">
        <div class="welcome-text">欢迎来到爱教练~</div>
        <div class="tip-text">为了保障您的权益，请完成手机绑定</div>
      </div>
      <div class="popup-content-bd">
        <form class="auth-form" @submit.prevent="onSubmit">
          <IField
            v-model.trim="formData.mobile"
            left-icon="icon-phone"
            placeholder="请输入手机号"
            maxlength="11"
          />
          <IField
            v-model.trim="formData.verifyCode"
            left-icon="icon-captcha"
            placeholder="6位短信验证码"
            maxlength="6"
          >
            <template #right>
              <span
                v-if="isGetCaptcha"
                class="field-right"
                @mousedown.capture.prevent.stop="getVerifyCode"
              >
                获取验证码
              </span>
              <CountDown
                class="count-down"
                v-else
                :time="time"
                format="ss 秒"
                @finish="handleCountDownFinish"
              />
            </template>
          </IField>
          <div class="login-protocol">
            <span>
              注册登录即表示同意
              <a href="/help?tabIndex=3"> 用户协议 </a>
              和
              <a href="/help?tabIndex=4"> 隐私政策 </a>
            </span>
          </div>
          <button class="submit-button">登录</button>
        </form>
      </div>
    </div>
  </Popup>
</template>

<script setup>
  import { ref, reactive, watch } from 'vue'
  import { Popup, Toast, CountDown } from 'vant'
  import IField from '@/views/login/components/IField'
  import { useSendLoginRegisterSMSCode } from '@/use/useSendVerifyCode'
  import Schema from 'async-validator'
  import { verifyUserInfoByMobile } from '@/api/user-server'
  import { localProxyStorage, sessionProxyStorage } from '@/utils/storage'
  import { loginByVerifyCode, sendRegistration } from '@/api/auth-server'
  import { validatorMobile } from '@/utils/validate'
  import { isStringValid } from '@/utils/is'

  const props = defineProps({
    show: { type: Boolean, default: false },
    callback: Function,
  })

  const emit = defineEmits(['update:show'])

  const popupShow = ref(false)

  const updatePopupShow = (val) => {
    popupShow.value = val
  }

  watch(() => props.show, updatePopupShow, { immediate: true })

  const time = ref(60 * 1000)
  const isGetCaptcha = ref(true)

  // 表单数据
  const initFormData = () => {
    return {
      mobile: '',
      verifyCode: '',
    }
  }

  const formData = reactive(initFormData())

  // 倒计时结束后
  const handleCountDownFinish = () => {
    isGetCaptcha.value = true
  }

  // 获取验证码
  const getVerifyCode = () => {
    useSendLoginRegisterSMSCode(formData, () => {
      isGetCaptcha.value = false
      Toast('验证码发送成功')
    })
  }

  const closePopup = () => {
    emit('update:show', false)
  }

  //账号登录表单校检
  let validatorFormData = new Schema({
    mobile: { required: true, validator: validatorMobile },
    verifyCode: { required: true, message: '请输入验证码' },
  })

  function afterLogin(data) {
    window.sensors.loginWithKey('$identity_login_id', data.userId)
    localProxyStorage.user = data
    closePopup()
    Toast('登录成功')
    window.location.reload()
  }

  const register = () => {
    let params = {
      mobile: formData.mobile,
      verifyCode: formData.verifyCode,
      identity: 'member',
    }

    // 有微信 uid 则携带上绑定微信账号
    if (isStringValid(sessionProxyStorage.weChatUId)) {
      params.unionId = sessionProxyStorage.weChatUId
    }

    // 有邀约码则携带上
    if (isStringValid(sessionProxyStorage.shareId)) {
      params.ijlShare = {
        shareId: sessionProxyStorage.shareId,
      }
    }

    sendRegistration(params).then((res) => {
      afterLogin(res.data)
    })
  }

  const login = () => {
    let loginLoading = Toast.loading({
      message: '登录中...',
      duration: 0,
    })

    let params = {
      mobile: formData.mobile,
      verifyCode: formData.verifyCode,
      expired: undefined,
    }

    // 有微信 uid 则携带上绑定微信账号
    if (isStringValid(sessionProxyStorage.weChatUId)) {
      params.unionId = sessionProxyStorage.weChatUId
    }

    loginByVerifyCode(params)
      .then((res) => {
        loginLoading.clear()
        afterLogin(res.data)
      })
      .catch(() => {})
  }

  const onSubmit = () => {
    validatorFormData
      .validate(formData)
      .then(() => {
        verifyUserInfoByMobile({ mobile: formData.mobile }).then((res) => {
          const { data } = res
          data ? login() : register()
        })
      })
      .catch(({ errors }) => {
        Toast(errors[0].message)
      })
  }
</script>

<style lang="scss" scoped>
  :global(.LoginPopup) {
    width: initial;
    left: var(--window-left);
    right: var(--window-right);
    border-radius: 0.1rem 0.1rem 0 0;
  }

  .field-right,
  .count-down {
    color: #e02020;
    font-size: 0.14rem;
    position: absolute;
    right: 0;
  }

  .popup-content {
    padding: 0.15rem;
  }

  .popup-content-hd {
    .welcome-text {
      font-size: 0.18rem;
      font-weight: 600;
      color: #1a1b1d;
    }

    .tip-text {
      font-size: 0.14rem;
      color: #616568;
    }
  }

  .popup-content-bd {
    .auth-form {
      margin-top: 0.12rem;
      padding: 0 0.1rem;
    }

    .login-protocol {
      margin-top: 0.14rem;
      text-align: center;

      span {
        font-size: 0.14rem;
        color: #b2b1b7;
      }

      a {
        color: #0091ff;
      }
    }

    .submit-button {
      width: 100%;
      height: 0.4rem;
      margin-top: 0.28rem;
      background: linear-gradient(270deg, #ff6445 0%, #ff9b26 100%);
      border-radius: 0.2rem;
      font-size: 0.14rem;
      font-weight: 600;
      color: #feffff;
    }
  }
</style>
