// 发送短信验证码
import { Toast } from 'vant'
import Schema from 'async-validator'
import { sendVerifyCode, sendBackPasswordVerifyCode } from '@/api/auth-server'
import { localProxyStorage } from '@/utils/storage'
import { validatorMobile } from '@/utils/validate'

let validatorResetPassword = new Schema({
  mobile: { required: true, validator: validatorMobile },
  imageCode: { required: true, message: '请输入图形验证码' },
})

const validatorLoginRegister = new Schema({
  mobile: { required: true, validator: validatorMobile },
})

// 发送登录注册短信验证码
export const useSendLoginRegisterSMSCode = (data, success) => {
  validatorLoginRegister
    .validate(data)
    .then(() => {
      let params = {
        mobile: data.mobile,
        // imageCode: data.imageCode, // 图形验证码
        // uid: localProxyStorage.uuid,
      }
      sendVerifyCode(params).then((res) => {
        Toast.success('短信已发送')
        if (success) {
          success(res)
        }
      })
    })
    .catch(({ errors }) => {
      Toast(errors[0].message)
    })
}

// 发送重置密码短信验证码
export const useSendResetPasswordSMSCode = (data, success) => {
  validatorResetPassword
    .validate(data)
    .then(() => {
      let params = {
        mobile: data.mobile,
        imageCode: data.imageCode, // 图形验证码
        uid: localProxyStorage.uuid,
      }
      sendBackPasswordVerifyCode(params).then((res) => {
        Toast.success('短信已发送')
        if (success) {
          success(res)
        }
      })
    })
    .catch(({ errors }) => {
      Toast(errors[0].message)
    })
}
