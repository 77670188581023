import { createRouter, createWebHistory } from 'vue-router'
import { sessionProxyStorage } from '@/utils/storage'
import { isLogin, toLogin } from '@/common'
import userRouters from './user'
import newsRouters from './news'
import categoryRouters from './category'
import coachRouters from './coach'
import searchRouters from './search'
import shopRouters from './shop'
import recruitRouters from './recruit'
import trainRouters from './train'
import userInfoRouters from './user-info'
import videoRouters from '@/router/video'
import worktable from './worktable'
import oauthRouters from '@/router/oauth'
import paymentRouters from './payment'
import studentClassRouters from './student'
import helpRouters from './help'
import actRouters from './act'
import Layout from '@/layout'
import { baseURL } from '@/config'
import { LoginPopup } from '@/views/login/components/LoginPopup'

const routes = [
  {
    path: '/',
    component: Layout,
    name: 'layout',
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          keepAlive: true,
          title: '首页',
        },
        component: () => import('../views/home/home-view'),
      },
      ...userRouters,
      ...newsRouters,
      ...categoryRouters,
      ...coachRouters,
      ...searchRouters,
      ...shopRouters,
      ...videoRouters,
      ...recruitRouters,
      ...trainRouters,
      ...worktable,
      ...userInfoRouters,
      ...oauthRouters,
      ...studentClassRouters,
      ...paymentRouters,
      ...helpRouters,
      ...actRouters,
      {
        path: '/page-template',
        name: 'pageTemplate',
        meta: {
          title: 'Page Template',
        },
        component: () => import('../views/pageTemplate'),
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('../views/Test'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404'),
    meta: {
      title: '页面不存在~',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // 正式环境的http链接自动跳转至https
  let localHost = 'http:' + baseURL.split(':')[1]
  if (window.location.origin === localHost) {
    window.location.href = window.location.href.replace('http://', 'https://')
  }

  // 判断是否需要鉴权
  if (to.meta?.requiresAuth) {
    if (!isLogin()) {
      toLogin()
      // 返回 false 以取消导航
      next(false)
      return
    }
  }

  // // 设置网页标题
  document.title = to.meta?.title || '私人教练,私人教练培训,高级私人教练网,爱教练私教网'
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute('content', '私人教练,私人教练培训,高级私人教练网,爱教练私教网')
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      'content',
      '爱教练私教网高级私人教练网提供私人教练培训信息,囊括网球教练,羽毛球教练,乒乓球教练,游泳教练,瑜伽教练,高尔夫教练等各类私人教练培训信息,私人教练一站式服务',
    )

  // 存储一个全局的 entryUrl 解决ios微信授权问题
  if (window.entryUrl === undefined || window.entryUrl === '') {
    window.entryUrl = location.href.split('#')[0]
  }

  // 有邀约 id 则存到 sessionStorage
  if (to.query.shareId) {
    // replace(/ /g, "+") 兼容旧的邀约 id 问题
    sessionProxyStorage.shareId = to.query.shareId.replace(/ /g, '+')
  }

  // 存放小程序token
  if (to.query.miniToken) {
    const user = {
      authToken: to.query.miniToken,
    }
    window.localStorage.setItem('_.user', JSON.stringify(user))
  }
  next()
})

router.afterEach(() => {
  const isLogin = localStorage.getItem('IS_LOGIN')
  if (isLogin) {
    LoginPopup()
    localStorage.removeItem('IS_LOGIN')
  }
})

export default router
