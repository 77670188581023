<template>
  <div class="ijl-skeleton-image" :style="{ width, height, marginTop }">
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64 896V128h896v768H64z m64-128l192-192 116.352 116.352L640 448l256 307.2V192H128v576z m224-480a96 96 0 1 1-0.064 192.064A96 96 0 0 1 352 288z"
      />
    </svg>
  </div>
</template>

<script>
  export default { name: 'ijlSkeletonImage' }
</script>

<script setup>
  defineProps({
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    marginTop: {
      type: String,
      default: '',
    },
  })
</script>

<style lang="scss">
  .ijl-skeleton-image {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #dcdde0;
      width: 22%;
      height: 22%;
    }
  }
</style>
