export const REG = {
  idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
  mobile: /^1[3456789]\d{9}$/,
  landline: /(^(\d{3,4}-)?\d{7,8})$|(13[0-9]{9})/, //座机
  email: /^\w+@[a-z0-9]+\.[a-z]+$/i,
  zipCode: /^[1-9][0-9]{5}$/,
  emoji: /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
  space: /\s+/g, // 空格
  numAndEnglish: /^[A-Za-z0-9]+$/, // 数字和字符
  positiveInteger: /^[+]{0,1}(\d+)$/, // 正整数(包括0)
  positiveInteger2: /^[1-9]\d*$/, // 正整数(不包括0)
  networkPath: /^(http|https):\/\//, // 是否为网络路径
  empty: /^\s*$/, // 字符串是否为空
}

/**
 * 公共的正则校验方法
 * @param {String} key 正则的名字 对应上面的REG
 * @param {String} val 值
 */
export function validate(key, val) {
  const reg = REG[key]
  return reg.test(val)
}

// 检查是否为空
export function checkEmpty(rule, value) {
  if (value === undefined || value === null) return false

  if (typeof value === 'string') {
    return value.trim() !== ''
  }

  return true
}

export function checkPhone(rule, value) {
  if (!value) {
    this.message = '手机号码不能为空'
    return false
  }
  if (!validate('mobile', value)) {
    this.message = '手机号码不正确'
    return false
  }
  return true
}

// 校检注册密码，修改密码
export function checkPassword(rule, value) {
  if (!value) return false
  let str = String(value)
  if (str.trim().length < 6) {
    this.message = '密码至少6个数字或字母'
    return false
  }

  if (str.trim().length > 12) {
    this.message = '密码长度不能超过12个数字或字母'
    return false
  }

  return true
}

// new
export const validatorMobile = (rule, value, callback) => {
  if (!value) {
    callback('请输入手机号码')
  }
  if (!validate('mobile', value)) {
    callback('请输入正确的手机号码')
  }
  callback()
}
