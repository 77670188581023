<template>
  <van-image v-bind="$attrs" :src="getOssURL(src)">
    <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]>
      <slot :name="key" />
    </template>
  </van-image>
</template>

<script>
  export default { name: 'IjlImage' }
</script>

<script setup>
  import { getOssURL } from '@/common'

  defineProps({
    src: {
      type: String,
      default: '',
    },
  })
</script>

<style lang="scss" scoped>
  .vertical {
    font-size: 0.1rem;
    height: 5em;
  }
</style>
