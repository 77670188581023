// # 用户服务

import http from '@/utils/axios'

// 重置密码
export const resetPassword = (params) => {
  return http.post('/user-server/api/userMember/v1/updateUserPassword/nL', params)
}

// 获取用户分享人信息
export const getShareUserInfo = (params) => {
  return http.post('/user-server/api/userMember/v1/getShareUserInfo/nL', params)
}

// 检验用户注册时候，输入的手机号码和用户名 是否存在
export const verifyUserInfoByMobile = (params) => {
  return http.post('/user-server/api/userMember/v1/checkUserInfoByMobile/nL', params)
}

// 更新用户信息(合并接口)
export const updateUserInfo = (params) => {
  return http.post('/user-server/api/userMemberMaterial/v1/updateUserInfo', params)
}
// 获取用户健康信息
export const getUserMemberProfileInfo = () => {
  return http.get('/user-server/api/userMemberMaterial/v1/getUserMemberProfileInfo')
}
// 获取用户邀约注册列表
export const getUserDistributionList = (params) => {
  return http.post('/user-server/api/userMember/v1/getUserDistributionList', params)
}

// 修改用户名
export const updateUserName = (params) => {
  return http.post('/user-server/api/userMember/v1/updateUserName', params)
}

// 通过旧密码方式修改密码
export const updatePasswordByOldPassword = (params) => {
  return http.post('/user-server/api/userMember/v1/updatePasswordByOldPassword', params)
}

export const getUserInfo = () => {
  return http.get('/user-server/api/userMember/v1/getLoginUserInfo')
}

// 绑定or更换手机号
export const updateUserMobile = (params) => {
  return http.post('/user-server/api/userMember/v1/updateUserMobile', params)
}

// 获取邀约总数
export const getUserInviteCount = () => {
  return http.get('/user-server/api/userMember/v1/getUserInviteCount')
}

// 关闭微信自动登录
export const offAutoWeChatAuth = () => {
  return http.get('/user-server/api/userMember/v1/userCloseAutomaticLogon')
}

// 通过微信给的 code 给后端查询账号信息
export const userSilenceAuth = (params) => {
  return http.post('/user-server/api/userWeCatAuth/v1/userSilenceAuth/nL', params)
}

// 通过微信手动授权获取的code,调用此接口获取 unionId
export const userWeChatAuth = (params) => {
  return http.post('/user-server/api/userWeCatAuth/v1/userWeChatAuth/nL', params)
}

// 获取用户的微信信息
export const reqUserWeChatInfo = () => {
  return http.post('/user-server/api/userWeCatAuth/v1/getUserWeChatInfo')
}

// 获取用户的消息列表
export const getUserMessageList = (params) => {
  return http.post('/user-server/api/userMessage/v1/getList', params)
}

// 获取用户未读消息
export const getUserMessageUnreadCount = (params) => {
  return http.post('/user-server/api/userMessage/v1/getUserMessageUnreadCount', params)
}

// 用户已读消息
export const userHasBeenRead = (params) => {
  return http.post('/user-server/api/userMessage/v1/userHasBeenRead', params)
}

// 用户一键已读消息
export const userOneKeyHasBeenRead = (params) => {
  return http.post('/user-server/api/userMessage/v1/userOneKeyHasBeenRead', params)
}

// 请求教练评论列表
export const reqCoachCommentList = (params) => {
  return http.get('/user-server/api/userComment/v1/getCoachCommentList/nL', { params })
}

// 用户发布评论
export const reqUserSubmitComment = (params) => {
  return http.post('/user-server/api/userComment/v1/userComment', params)
}

// 用户回复评论
export const reqUserReplyComment = (params) => {
  return http.post('/user-server/api/userComment/v1/userReply', params)
}

// 获取评论的回复列表
export const reqCommentReplyList = (params) => {
  return http.get('/user-server/api/userComment/v1/getReplyList/nL', { params })
}

// 获取评论总数
export const reqGetCoachCommentCount = (params) => {
  return http.get('/user-server/api/userComment/v1/getCoachCommentListCount/nL', { params })
}

// 获取单条评论详情
export const reqGetCommentDetails = (params) => {
  return http.get('/user-server/api/userComment/v1/getCommentDetails/nL', { params })
}

// 获取单条评论详情
export const reqGetSubCommentCount = (params) => {
  return http.get('/user-server/api/userComment/v1/getReplyListCount/nL', { params })
}
