<template>
  <ijiaolian-page>
    <page-loading v-if="loading">
      <slot name="loading" />
    </page-loading>

    <template v-if="!loading">
      <page-head v-if="isPageHeadShow" v-bind="navigationBar">
        <slot name="navBarRight" />
      </page-head>

      <page-refresh v-if="enablePullDownRefresh" />
      <page-body
        v-if="enablePullDownRefresh"
        @touchstart="_touchstart"
        @touchmove="_touchmove"
        @touchend="_touchend"
        @touchcancel="_touchcancel"
        :navigationBarType="navigationBarType"
      >
        <slot name="page" />
      </page-body>
      <page-body :navigationBarType="navigationBarType" v-else>
        <slot name="page" />
      </page-body>
    </template>
  </ijiaolian-page>
</template>

<script>
  export default { name: 'Page' }
</script>

<script setup>
  import { watch, computed, onMounted, onUnmounted } from 'vue'

  import PageHead from '@/layout/components/Page/PageHead'
  import PageRefresh from '@/layout/components/Page/PageRefresh'
  import PageBody from '@/layout/components/Page/PageBody'
  import PageLoading from '@/layout/components/Page/PageLoading'
  import usePullToRefresh from '@/layout/components/Page/pull-to-refresh'

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    navigationBarTitleImage: {
      type: String,
      default: '',
    },
    navigationBarDefaultHomePath: {
      type: String,
      default: '/',
    },
    navigationBarBackgroundColor: {
      type: String,
      default: '#fff',
    },
    navigationBarTextColor: {
      type: String,
      default: '#000',
    },
    navigationBarType: {
      type: String,
      default: 'default', // transparent
    },
    navigationBarSearchInput: {
      type: [Boolean, Object, String],
      default: '',
    },
    navigationBarCoverage: {
      type: Number,
      default: 132,
    },
    enablePullDownRefresh: {
      type: Boolean,
      default: false,
    },
    pullToRefreshOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits([
    //下拉刷新
    'pullToRefresh',
    // 页面显示
    'pageshow',
    // 页面离开
    'pagehide',
    // 页面进入前台
    'pageEnterForeground',
    // 页面进入后台
    'pageEnterBackground',
  ])

  let touchstart, touchmove, touchend, touchcancel
  const _touchstart = (event) => touchstart && touchstart(event)
  const _touchmove = (event) => touchmove && touchmove(event)
  const _touchend = (event) => touchend && touchend(event)
  const _touchcancel = (event) => touchcancel && touchcancel(event)

  const initPullDownRefresh = () => {
    const res = usePullToRefresh(props.pullToRefreshOptions, (params) => {
      emit('pullToRefresh', params)
    })
    touchstart = res.touchstart
    touchmove = res.touchmove
    touchend = res.touchend
    touchcancel = res.touchcancel
  }

  const _enablePullDownRefresh = computed(() => {
    return props.enablePullDownRefresh && !props.loading
  })

  watch(
    _enablePullDownRefresh,
    (value) => {
      if (!value) return
      initPullDownRefresh()
    },
    { immediate: true },
  )

  const navigationBar = computed(() => {
    let data = {
      type: props.navigationBarType,
      title: props.title,
      textColor: props.navigationBarTextColor,
      titleImage: props.navigationBarTitleImage,
      backgroundColor: props.navigationBarBackgroundColor,
      defaultHomePath: props.navigationBarDefaultHomePath,
      coverage: props.navigationBarCoverage,
    }

    if (typeof props.navigationBarSearchInput === 'object') {
      data.searchInput = Object.assign(
        {
          autoFocus: false,
          align: 'center',
          color: '#000000',
          backgroundColor: 'rgba(255,255,255,0.5)',
          borderRadius: '0px',
          placeholder: '',
          placeholderColor: '#CCCCCC',
          disabled: false,
        },
        props.navigationBarSearchInput,
      )
    }

    return data
  })

  const isPageHeadShow = computed(() => {
    const isMiniprogramEvn = localStorage.getItem('BROWSER_ENV') === 'miniprogram'
    return props.navigationBarType !== 'none' && !isMiniprogramEvn
  })

  const onPageshow = (event) => {
    emit('pageshow', event)
  }

  const onPagehide = (event) => {
    emit('pagehide', event)
  }

  // 页面进入到前台
  const onPageEnterForeground = () => {
    emit('pageEnterForeground')
  }

  // 页面进入到后台
  const onPageEnterBackground = () => {
    emit('pageEnterBackground')
  }

  const handleVisibilitychange = () => {
    if (document.visibilityState === 'visible') {
      onPageEnterForeground()
    } else {
      onPageEnterBackground()
    }
  }

  onMounted(() => {
    window.addEventListener('pageshow', onPageshow)
    window.addEventListener('pagehide', onPagehide)
    document.addEventListener('visibilitychange', handleVisibilitychange)
  })

  onUnmounted(() => {
    window.removeEventListener('pageshow', onPageshow)
    window.removeEventListener('pagehide', onPagehide)
    document.removeEventListener('visibilitychange', handleVisibilitychange)
  })
</script>

<style scoped lang="scss">
  ijiaolian-page {
    width: 100%;
    height: 100%;
    display: block;
  }

  ijiaolian-page::before {
    content: '';
    display: table;
  }
</style>
