import hexToRgba from '@/layout/utils/color'

function useTransparent(option) {
  let handleScroll

  const onTransparentModel = () => {
    const transparentElemStyle = document.querySelector('.page-head-transparent').style
    const titleElem = document.querySelector('.page-head-title')
    const pageRightElem = document.querySelector('.page-head-ft')

    const buttonElem = document.querySelectorAll('.page-head-btn')
    const borderElemStyle = document.querySelector('.head-border').style

    const oldColors = []
    const buttonElemElemsStyles = []
    for (let i = 0; i < buttonElem.length; i++) {
      const elem = buttonElem[i]
      oldColors.push(getComputedStyle(elem).backgroundColor)
      buttonElemElemsStyles.push(elem.style)
    }

    let lastAlpha = 0

    const { r, g, b } = hexToRgba(option.backgroundColor)
    const borderRgba = hexToRgba(option.borderColor)

    handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

      const alpha = Math.min(scrollTop / option.offset, 1)
      if (alpha === 1 && lastAlpha === 1) {
        return
      }

      // 设置按钮字体颜色
      if (alpha > 0.5 && lastAlpha <= 0.5) {
        buttonElemElemsStyles.forEach(function (buttonElemElemsStyle) {
          buttonElemElemsStyle.color = option.textColor
        })
      } else if (alpha <= 0.5 && lastAlpha > 0.5) {
        buttonElemElemsStyles.forEach(function (buttonElemElemsStyle) {
          buttonElemElemsStyle.color = '#fff'
        })
      }

      lastAlpha = alpha

      if (titleElem) {
        titleElem.style.opacity = alpha
      }

      if (pageRightElem) {
        pageRightElem.style.opacity = alpha
      }

      transparentElemStyle.backgroundColor = `rgba(${r},${g},${b},${alpha})`
      borderElemStyle.backgroundColor = `rgba(${borderRgba.r},${borderRgba.g},${borderRgba.b},${alpha})`

      buttonElemElemsStyles.forEach(function (buttonElemElemsStyle, index) {
        const oldColor = oldColors[index]
        // eslint-disable-next-line
        let rgba = oldColor.match(/[\d+\.]+/g)
        rgba[3] = (1 - alpha) * (rgba.length === 4 ? rgba[3] : 1)
        buttonElemElemsStyle.backgroundColor = `rgba(${rgba})`
      })
    }
    window.addEventListener('scroll', handleScroll, true)
  }

  const offTransparentModel = () => {
    window.removeEventListener('scroll', handleScroll, true)
  }

  return {
    onTransparentModel,
    offTransparentModel,
  }
}

export default useTransparent
