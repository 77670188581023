import { isIOS, isWeChat } from '@/utils'

export default [
  {
    path: '/pay/course',
    name: 'payCourse',
    component: () => import('../views/payment/pay-course'),
    beforeEnter(to) {
      if (isIOS() && isWeChat() && to.path !== location.pathname) {
        // window.location.href = ;
        window.location.replace(`${window.location.origin}${to.fullPath}`)
      }
    },
    meta: { title: '支付' },
    children: [
      {
        path: 'ap-guide',
        name: 'apGuide',
        component: () => import('../views/payment/ap-guide'),
      },
    ],
  },
  {
    path: '/pay/ap-success',
    name: 'apSuccess',
    component: () => import('../views/payment/ap-success'),
    meta: { title: '支付成功' },
  },
  {
    path: '/pay/ap-error',
    name: 'apError',
    component: () => import('../views/payment/ap-error'),
    meta: { title: '取消支付' },
  },
  {
    path: '/browser-pay',
    name: 'browserPay',
    component: () => import('../views/payment/browser-pay'),
  },
]
