<template>
  <div class="ijl-skeleton-row" :style="getStyles">
    <slot />
  </div>
</template>

<script>
  export default { name: 'ijlSkeletonRow' }
</script>

<script setup>
  import { computed, useSlots } from 'vue'

  const props = defineProps({
    width: {
      type: String,
      default: '100%',
    },
    height: String,
    marginTop: {
      type: String,
      default: '0.1rem',
    },
  })

  const slots = useSlots()

  const hasSlot = computed(() => {
    return !!slots.default
  })

  const getStyles = computed(() => {
    const styles = {
      width: props.width,
      height: props.height,
      marginTop: props.marginTop,
    }

    if (hasSlot.value) {
      styles.background = 'transparent'
      styles.backgroundSize = 'auto'
    }

    return styles
  })
</script>

<style lang="scss">
  .ijl-skeleton-row {
    display: flex;
    height: 0.16rem;
    background: #f2f2f2;
  }
</style>
