<template>
  <div class="ijl-goods-action">
    <slot></slot>
  </div>
</template>

<script>
  export default { name: 'IjlGoodsAction' }
</script>

<script setup>
  import { useChildren } from '@/use/useRelation'

  const { linkChildren } = useChildren('IjlGoodsAction')

  const getChildIndex = () => {}

  linkChildren({
    getChildIndex,
  })
</script>

<style lang="scss" scoped>
  .ijl-goods-action {
    position: fixed;
    right: var(--window-right);
    bottom: 0;
    left: var(--window-left);
    display: flex;
    align-items: center;
    box-sizing: content-box;
    height: 0.5rem;
    box-shadow: 0 -0.01rem 0.04rem 0 rgba(0, 0, 0, 0.06);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    z-index: 998;
  }
</style>
