import { iosRefresh } from '@/common'

export default [
  {
    path: '/recruit-list',
    name: 'recruitList',
    component: () => import('../views/recruit/recruit-list'),
    meta: {
      title: '教练招聘',
      keepAlive: true,
    },
  },
  {
    path: '/recruit-details/:recruitId',
    name: 'recruitDetails',
    beforeEnter: iosRefresh,
    component: () => import('../views/recruit/recruit-details'),
    meta: {
      title: '招聘详情',
    },
  },
]
