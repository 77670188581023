import { baseURL, appid } from '@/config'

/**
 * 获取微信授权地址
 * @param {String} appId
 * @param {String} scope (snsapi_userinfo | snsapi_base)
 *
 */
function getWxAutoUrl(appId, scope = 'snsapi_base') {
  let redirectUrl = baseURL + '/oauth/wechat'
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${scope}#wechat_redirect`
}

export function toWxAuth(scope = 'snsapi_base') {
  location.href = getWxAutoUrl(appid, scope)
}
