import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from '@/utils/vant.config'
import { Lazyload } from 'vant'
import directive from '@/directives/install'
import VConsole from 'vconsole'
import components from '@/components'
import Page from '@/layout/components/Page'
import monitor from '@/utils/monitor'
import { getBrowserEnv } from '@/utils'

// css样式引入
import 'normalize.css'
import './styles/index.scss'

let app = createApp(App)

app
  .use(store)
  .use(router)
  .use(vant)
  .use(components)
  .use(directive)
  .use(Lazyload, { lazyComponent: true })

// 注册组件
app.component(Page.name, Page)

// vue3 路由已经是异步的了，这里要处理一下 等待路由 ready好后，再挂载程序
router.isReady().then(() => app.mount('#app'))

if (
  process.env.VUE_APP_RUN_ENV === 'development' ||
  process.env.VUE_APP_RUN_ENV === 'testing' ||
  process.env.VUE_APP_RUN_ENV === 'pre'
) {
  new VConsole()
}

// 百度埋点统计
if (process.env.VUE_APP_RUN_ENV === 'production') {
  var _hmt = _hmt || []
  window._hmt = _hmt // 将_hmt挂载到window下
  ;(function () {
    var hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?70d1ac7d879283cd59c73e4057b2262d'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
  router.beforeEach((to, from, next) => {
    if (_hmt) {
      if (to.path) {
        _hmt.push(['_trackPageview', to.fullPath])
      }
    }
    next()
  })
}

// 埋点注入用户信息
monitor.injectUserInfo()

// 储存当前浏览器的环境
localStorage.setItem('BROWSER_ENV', getBrowserEnv())
