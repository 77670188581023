export default {
  tabBar: {
    color: '#B2B1B7',
    selectedColor: '#FF9B26',
    borderColor: null,
    backgroundColor: '#F8F8F8',
    list: [
      {
        pagePath: '/',
        iconPath: require('../assets/images/icon/icon-home.png'),
        selectedIconPath: require('../assets/images/icon/icon-select-home.png'),
        text: '首页',
      },
      {
        pagePath: '/category',
        iconPath: require('../assets/images/icon/icon-query-coach.png'),
        selectedIconPath: require('../assets/images/icon/icon-select-query-coach.png'),
        text: '找教练',
      },
      {
        pagePath: '/user',
        iconPath: require('../assets/images/icon/icon-user.png'),
        selectedIconPath: require('../assets/images/icon/icon-select-user.png'),
        text: '我的',
      },
    ],
  },
}
