import { iosRefresh } from '@/common'

export default [
  {
    path: '/video-list',
    name: 'videoList',
    component: () => import('../views/video/video-list'),
    meta: {
      title: '教学视频',
      keepAlive: true,
    },
  },
  {
    path: '/video/details/:id',
    name: 'videoDetails',
    beforeEnter: iosRefresh,
    component: () => import('../views/video/video-detail'),
    meta: {
      title: '详情',
    },
  },
]
